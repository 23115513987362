.Contact{
    text-align: center;
}

.White-logo{
    pointer-events: none;
}

.HTML-header-span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.HTML-body{
    background-color: #282c34;
    padding:20px;
    font-size: large;
}

.ContactUs-body{
    background-color: #282c34;
    padding: inherit;
    display: inline-block;
    align-items: center;
    justify-content: left;
}

.contactForm{
    margin: 5px;
}