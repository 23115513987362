/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 *
 *  Theme Name: Sera - OnePage Multi-Purpose Theme
 *  Theme URI: http://gleesik.ro/
 *  Author: Gleesik
 *  Author URI: http://gleesik.ro/
 *  Description: OnePage Multi-Purpose Theme
 *  Version: 1.0
 *  License: GNU General Public License v2 or later
 *  License URI: http://www.gnu.org/licenses/gpl-2.0.html
 *  Tags: onepage, blog, multiple-columns, left-sidebar, right-sidebar, accessibility-ready, custom-background, custom-colors, custom-header, custom-logo, custom-menu, editor-style, featured-images, post-formats, threaded-comments, translation-ready
 *  Text Domain: sera-onepage
 *
 *  @package gleesik_project
 *
 **/
/**
 * -----------------------------------------------------------------------------
 *
 *  [ Table of contents ]
 *
 *  1. Modules
 *    1.1 Reset Css
 *    1.2 Configuration
 *    1.3 Color Skin
 *        1.3.1 Light Theme
 *        1.3.2 Dark Theme
 *    1.4 Class Tools
 *    1.5 Bootstrap Grid System
 *    1.6 Responsive Embed
 *        1.6.1 16:6 Video Aspect Ratio
 *        1.6.2 4:3 Video Aspect Ratio
 *        1.6.3 Standard Audio
 *    1.7 Animations
 *    1.8 Owl Carousel
 *    1.9 Font 7 Stroke Icons
 *    1.10 Flaticon Social Icons
 *  2. Components
 *    2.1 Forms
 *        2.1.1 Inputs
 *        2.1.2 Textarea
 *        2.1.3 Buttons
 *        2.1.4 Form Group
 *    2.2 Social Icons
 *    2.3 Tags Box
 *    2.4 Buttons
 *  3. Layout
 *    3.1 Global
 *    3.4 Sections
 *        3.4.1 Header
 *            3.4.1.1 Header Responsive
 *            3.4.1.2 Navigation Menu
 *            3.4.1.3 Mobile Navigation
 *            3.4.1.4 Buttons CTA (Call To Actions)
 *         3.4.2 Offer Banner
 *         3.4.3 About
 *         3.4.4 Features
 *         3.4.5 Portfolio
 *             3.4.5.1 Portfolio Menu
 *             3.4.5.2 Portfolio Grid
 *         3.4.6 Parallax Features
 *         3.4.7 Testimonials
 *         3.4.8 Blog
 *         3.4.9 Team
 *         3.4.10 Prices
 *         3.4.11 Contact
 *         3.4.12 Footer
 *    3.5 Sidebar
 * -----------------------------------------------------------------------------
 **/
/**
 *
 *  CSS Reset
 *
 *  @package gleesik_module
 *
 **/
 html, body, div, span, applet, object, iframe,
 h1, h2, h3, h4, h5, h6, p, blockquote, pre,
 a, abbr, acronym, address, big, cite, code,
 del, dfn, em, img, ins, kbd, q, s, samp,
 small, strike, strong, sub, sup, tt, var,
 b, u, i, center,
 dl, dt, dd, ol, ul, li,
 fieldset, form, label, legend,
 table, caption, tbody, tfoot, thead, tr, th, td,
 article, aside, canvas, details, embed,
 figure, figcaption, footer, header, hgroup,
 menu, nav, output, ruby, section, summary,
 time, mark, audio, video {
   margin: 0;
   padding: 0;
   border: 0;
   font-size: 100%;
   font: inherit;
   vertical-align: baseline;
 }
 
 /* HTML5 display-role reset for older browsers */
 article, aside, details, figcaption, figure,
 footer, header, hgroup, menu, nav, section {
   display: block;
 }
 
 body {
   line-height: 1;
 }
 
 ol, ul {
   list-style: none;
 }
 
 blockquote, q {
   quotes: none;
 }
 
 blockquote:before, blockquote:after,
 q:before, q:after {
   content: '';
   content: none;
 }
 
 table {
   border-collapse: collapse;
   border-spacing: 0;
 }
 
 /**
  *
  *  Theme Style Configuration
  *
  *  @package gleesik_module
  *
  *
  *
  *
  *  ##### Typography
  *  $font-family: 'Lato'; // Google Font
  *  $global-font-size: 16px; // Default Global Font Size
  *
  *  ##### Animations Timing
  *  $transition-links: 300ms; // Default Global Animation Timing
  *  $animation-arrow-header-time: 2.5s;
  *
  *  ##### Animations Types
  *  $transition-links-type: ease-in-out; // Default Global Transition Type
  *  $animation-arrow-header-type: ease-in-out;
  *
  *  ##### Mobile Menu Settings
  *  $cubic: cubic-bezier(0.4, 0.01, 0.165, 0.99);
  *
  *
  *
  *
  **/
 /**
  *
  *  Color Skin
  *
  *  @package gleesik_module
  *
  *
  *
  *
  *  ##### Theme Colors Primary
  *  $blue: #21c2f8;
  *  $green: #2ecc71;
  *
  *  ##### Global Pallete
  *  $white: #fff;
  *  $black: #000;
  *  $black-transparent: rgba($black, 0.9);
  *  $light-blue: #f4f8f9;
  *
  *  ##### Primary & Secondary
  *  $primary: $blue; // This is primary theme color
  *  $secondary: #2c3e50;
  *
  *  ##### Global Text Color
  *  $global-text: #848484;
  *
  *  ##### Gray Pallete
  *  $light-gray: #e3e9ed;
  *  $simple-gray: #bdc3c7;
  *  $normal-gray: #464646;
  *  $dark-gray: #202020;
  *
  *
  *
  *
  **/
 /**
  *
  *  Global Classes Style
  *
  *  @package gleesik_module
  *
  **/
 .text-center {
   text-align: center;
 }
 
 .text-left {
   text-align: left;
 }
 
 .text-right {
   text-align: right;
 }
 
 .text-focus {
   color: #21c2f8;
 }
 
 .parent {
   position: relative;
 }
 
 .child-center-v {
   position: absolute;
   top: 50%;
   -webkit-transform: translateY(-50%);
       -ms-transform: translateY(-50%);
           transform: translateY(-50%);
 }
 
 .child-center-h {
   position: absolute;
   left: 50%;
   -webkit-transform: translateX(-50%);
       -ms-transform: translateX(-50%);
           transform: translateX(-50%);
 }
 
 .child-center {
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
       -ms-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
 }
 
 .circle {
   -webkit-border-radius: 120%;
           border-radius: 120%;
 }
 
 .hide {
   display: none !important;
 }
 
 .show {
   display: block !important;
 }
 
 .invisible {
   visibility: hidden;
 }
 
 .affix {
   position: fixed;
 }
 
 .parallax {
   overflow: hidden !important;
   background: none !important;
 }
 
 .parallax .cover {
   position: absolute;
   display: block;
   width: 100%;
   height: 100%;
   z-index: -999999;
 }
 
 .parallax .cover img {
   position: absolute;
   display: block;
   width: auto;
   min-width: 100%;
   min-height: 120%;
   max-width: inherit;
   max-height: 140%;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
       -ms-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
 }
 
 .parallax .cover .content {
   position: absolute;
   display: block;
   background: rgba(0, 0, 0, 0.7);
   width: 100%;
   height: 100%;
   z-index: 2;
 }
 
 /**
 *
 *  Bootstrap Grid System
 *
 *  @package gleesik_module
 *
 **/
 @-ms-viewport {
   width: device-width;
 }
 
 @media (max-width: 767px) {
   .hidden-xs {
     display: none !important;
   }
 }
 
 @media (min-width: 768px) and (max-width: 991px) {
   .hidden-sm {
     display: none !important;
   }
 }
 
 @media (min-width: 992px) and (max-width: 1199px) {
   .hidden-md {
     display: none !important;
   }
 }
 
 @media (min-width: 1200px) {
   .hidden-lg {
     display: none !important;
   }
 }
 
 .container {
   margin-right: auto;
   margin-left: auto;
   padding-left: 15px;
   padding-right: 15px;
 }
 
 @media (min-width: 768px) {
   .container {
     width: 750px;
   }
 }
 
 @media (min-width: 992px) {
   .container {
     width: 970px;
   }
 }
 
 @media (min-width: 1200px) {
   .container {
     width: 1170px;
   }
 }
 
 .container-fluid {
   margin-right: auto;
   margin-left: auto;
   padding-left: 15px;
   padding-right: 15px;
 }
 
 .row {
   margin-left: -15px;
   margin-right: -15px;
 }

 
 .col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
   position: relative;
   min-height: 1px;
   padding-left: 15px;
   padding-right: 15px;
 }
 
 .col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
   float: left;
 }
 
 .col-xs-12 {
   width: 100%;
 }
 
 .col-xs-11 {
   width: 91.66666667%;
 }
 
 .col-xs-10 {
   width: 83.33333333%;
 }
 
 .col-xs-9 {
   width: 75%;
 }
 
 .col-xs-8 {
   width: 66.66666667%;
 }
 
 .col-xs-7 {
   width: 58.33333333%;
 }
 
 .col-xs-6 {
   width: 50%;
 }
 
 .col-xs-5 {
   width: 41.66666667%;
 }
 
 .col-xs-4 {
   width: 33.33333333%;
 }
 
 .col-xs-3 {
   width: 25%;
 }
 
 .col-xs-2 {
   width: 16.66666667%;
 }
 
 .col-xs-1 {
   width: 8.33333333%;
 }
 
 .col-xs-pull-12 {
   right: 100%;
 }
 
 .col-xs-pull-11 {
   right: 91.66666667%;
 }
 
 .col-xs-pull-10 {
   right: 83.33333333%;
 }
 
 .col-xs-pull-9 {
   right: 75%;
 }
 
 .col-xs-pull-8 {
   right: 66.66666667%;
 }
 
 .col-xs-pull-7 {
   right: 58.33333333%;
 }
 
 .col-xs-pull-6 {
   right: 50%;
 }
 
 .col-xs-pull-5 {
   right: 41.66666667%;
 }
 
 .col-xs-pull-4 {
   right: 33.33333333%;
 }
 
 .col-xs-pull-3 {
   right: 25%;
 }
 
 .col-xs-pull-2 {
   right: 16.66666667%;
 }
 
 .col-xs-pull-1 {
   right: 8.33333333%;
 }
 
 .col-xs-pull-0 {
   right: auto;
 }
 
 .col-xs-push-12 {
   left: 100%;
 }
 
 .col-xs-push-11 {
   left: 91.66666667%;
 }
 
 .col-xs-push-10 {
   left: 83.33333333%;
 }
 
 .col-xs-push-9 {
   left: 75%;
 }
 
 .col-xs-push-8 {
   left: 66.66666667%;
 }
 
 .col-xs-push-7 {
   left: 58.33333333%;
 }
 
 .col-xs-push-6 {
   left: 50%;
 }
 
 .col-xs-push-5 {
   left: 41.66666667%;
 }
 
 .col-xs-push-4 {
   left: 33.33333333%;
 }
 
 .col-xs-push-3 {
   left: 25%;
 }
 
 .col-xs-push-2 {
   left: 16.66666667%;
 }
 
 .col-xs-push-1 {
   left: 8.33333333%;
 }
 
 .col-xs-push-0 {
   left: auto;
 }
 
 .col-xs-offset-12 {
   margin-left: 100%;
 }
 
 .col-xs-offset-11 {
   margin-left: 91.66666667%;
 }
 
 .col-xs-offset-10 {
   margin-left: 83.33333333%;
 }
 
 .col-xs-offset-9 {
   margin-left: 75%;
 }
 
 .col-xs-offset-8 {
   margin-left: 66.66666667%;
 }
 
 .col-xs-offset-7 {
   margin-left: 58.33333333%;
 }
 
 .col-xs-offset-6 {
   margin-left: 50%;
 }
 
 .col-xs-offset-5 {
   margin-left: 41.66666667%;
 }
 
 .col-xs-offset-4 {
   margin-left: 33.33333333%;
 }
 
 .col-xs-offset-3 {
   margin-left: 25%;
 }
 
 .col-xs-offset-2 {
   margin-left: 16.66666667%;
 }
 
 .col-xs-offset-1 {
   margin-left: 8.33333333%;
 }
 
 .col-xs-offset-0 {
   margin-left: 0%;
 }
 
 @media (min-width: 768px) {
   .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
     float: left;
   }
   .col-sm-12 {
     width: 100%;
   }
   .col-sm-11 {
     width: 91.66666667%;
   }
   .col-sm-10 {
     width: 83.33333333%;
   }
   .col-sm-9 {
     width: 75%;
   }
   .col-sm-8 {
     width: 66.66666667%;
   }
   .col-sm-7 {
     width: 58.33333333%;
   }
   .col-sm-6 {
     width: 50%;
   }
   .col-sm-5 {
     width: 41.66666667%;
   }
   .col-sm-4 {
     width: 33.33333333%;
   }
   .col-sm-3 {
     width: 25%;
   }
   .col-sm-2 {
     width: 16.66666667%;
   }
   .col-sm-1 {
     width: 8.33333333%;
   }
   .col-sm-pull-12 {
     right: 100%;
   }
   .col-sm-pull-11 {
     right: 91.66666667%;
   }
   .col-sm-pull-10 {
     right: 83.33333333%;
   }
   .col-sm-pull-9 {
     right: 75%;
   }
   .col-sm-pull-8 {
     right: 66.66666667%;
   }
   .col-sm-pull-7 {
     right: 58.33333333%;
   }
   .col-sm-pull-6 {
     right: 50%;
   }
   .col-sm-pull-5 {
     right: 41.66666667%;
   }
   .col-sm-pull-4 {
     right: 33.33333333%;
   }
   .col-sm-pull-3 {
     right: 25%;
   }
   .col-sm-pull-2 {
     right: 16.66666667%;
   }
   .col-sm-pull-1 {
     right: 8.33333333%;
   }
   .col-sm-pull-0 {
     right: auto;
   }
   .col-sm-push-12 {
     left: 100%;
   }
   .col-sm-push-11 {
     left: 91.66666667%;
   }
   .col-sm-push-10 {
     left: 83.33333333%;
   }
   .col-sm-push-9 {
     left: 75%;
   }
   .col-sm-push-8 {
     left: 66.66666667%;
   }
   .col-sm-push-7 {
     left: 58.33333333%;
   }
   .col-sm-push-6 {
     left: 50%;
   }
   .col-sm-push-5 {
     left: 41.66666667%;
   }
   .col-sm-push-4 {
     left: 33.33333333%;
   }
   .col-sm-push-3 {
     left: 25%;
   }
   .col-sm-push-2 {
     left: 16.66666667%;
   }
   .col-sm-push-1 {
     left: 8.33333333%;
   }
   .col-sm-push-0 {
     left: auto;
   }
   .col-sm-offset-12 {
     margin-left: 100%;
   }
   .col-sm-offset-11 {
     margin-left: 91.66666667%;
   }
   .col-sm-offset-10 {
     margin-left: 83.33333333%;
   }
   .col-sm-offset-9 {
     margin-left: 75%;
   }
   .col-sm-offset-8 {
     margin-left: 66.66666667%;
   }
   .col-sm-offset-7 {
     margin-left: 58.33333333%;
   }
   .col-sm-offset-6 {
     margin-left: 50%;
   }
   .col-sm-offset-5 {
     margin-left: 41.66666667%;
   }
   .col-sm-offset-4 {
     margin-left: 33.33333333%;
   }
   .col-sm-offset-3 {
     margin-left: 25%;
   }
   .col-sm-offset-2 {
     margin-left: 16.66666667%;
   }
   .col-sm-offset-1 {
     margin-left: 8.33333333%;
   }
   .col-sm-offset-0 {
     margin-left: 0%;
   }
 }
 
 @media (min-width: 992px) {
   .col, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
     float: left;
   }
   .col-md-12 {
     width: 100%;
   }
   .col-md-11 {
     width: 91.66666667%;
   }
   .col-md-10 {
     width: 83.33333333%;
   }
   .col-md-9 {
     width: 75%;
   }
   .col-md-8 {
     width: 66.66666667%;
   }
   .col-md-7 {
     width: 58.33333333%;
   }
   .col-md-6 {
     width: 50%;
   }
   .col-md-5 {
     width: 41.66666667%;
   }
   .col-md-4 {
     width: 33.33333333%;
   }
   .col-md-3 {
     width: 25%;
   }
   .col-md-2 {
     width: 16.66666667%;
   }
   .col-md-1 {
     width: 8.33333333%;
   }
   .col-md-pull-12 {
     right: 100%;
   }
   .col-md-pull-11 {
     right: 91.66666667%;
   }
   .col-md-pull-10 {
     right: 83.33333333%;
   }
   .col-md-pull-9 {
     right: 75%;
   }
   .col-md-pull-8 {
     right: 66.66666667%;
   }
   .col-md-pull-7 {
     right: 58.33333333%;
   }
   .col-md-pull-6 {
     right: 50%;
   }
   .col-md-pull-5 {
     right: 41.66666667%;
   }
   .col-md-pull-4 {
     right: 33.33333333%;
   }
   .col-md-pull-3 {
     right: 25%;
   }
   .col-md-pull-2 {
     right: 16.66666667%;
   }
   .col-md-pull-1 {
     right: 8.33333333%;
   }
   .col-md-pull-0 {
     right: auto;
   }
   .col-md-push-12 {
     left: 100%;
   }
   .col-md-push-11 {
     left: 91.66666667%;
   }
   .col-md-push-10 {
     left: 83.33333333%;
   }
   .col-md-push-9 {
     left: 75%;
   }
   .col-md-push-8 {
     left: 66.66666667%;
   }
   .col-md-push-7 {
     left: 58.33333333%;
   }
   .col-md-push-6 {
     left: 50%;
   }
   .col-md-push-5 {
     left: 41.66666667%;
   }
   .col-md-push-4 {
     left: 33.33333333%;
   }
   .col-md-push-3 {
     left: 25%;
   }
   .col-md-push-2 {
     left: 16.66666667%;
   }
   .col-md-push-1 {
     left: 8.33333333%;
   }
   .col-md-push-0 {
     left: auto;
   }
   .col-md-offset-12 {
     margin-left: 100%;
   }
   .col-md-offset-11 {
     margin-left: 91.66666667%;
   }
   .col-md-offset-10 {
     margin-left: 83.33333333%;
   }
   .col-md-offset-9 {
     margin-left: 75%;
   }
   .col-md-offset-8 {
     margin-left: 66.66666667%;
   }
   .col-md-offset-7 {
     margin-left: 58.33333333%;
   }
   .col-md-offset-6 {
     margin-left: 50%;
   }
   .col-md-offset-5 {
     margin-left: 41.66666667%;
   }
   .col-md-offset-4 {
     margin-left: 33.33333333%;
   }
   .col-md-offset-3 {
     margin-left: 25%;
   }
   .col-md-offset-2 {
     margin-left: 16.66666667%;
   }
   .col-md-offset-1 {
     margin-left: 8.33333333%;
   }
   .col-md-offset-0 {
     margin-left: 0%;
   }
 }
 
 @media (min-width: 1200px) {
   .col, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
     float: left;
   }
   .col-lg-12 {
     width: 100%;
   }
   .col-lg-11 {
     width: 91.66666667%;
   }
   .col-lg-10 {
     width: 83.33333333%;
   }
   .col-lg-9 {
     width: 75%;
   }
   .col-lg-8 {
     width: 66.66666667%;
   }
   .col-lg-7 {
     width: 58.33333333%;
   }
   .col-lg-6 {
     width: 50%;
   }
   .col-lg-5 {
     width: 41.66666667%;
   }
   .col-lg-4 {
     width: 33.33333333%;
   }
   .col-lg-3 {
     width: 25%;
   }
   .col-lg-2 {
     width: 16.66666667%;
   }
   .col-lg-1 {
     width: 8.33333333%;
   }
   .col-lg-pull-12 {
     right: 100%;
   }
   .col-lg-pull-11 {
     right: 91.66666667%;
   }
   .col-lg-pull-10 {
     right: 83.33333333%;
   }
   .col-lg-pull-9 {
     right: 75%;
   }
   .col-lg-pull-8 {
     right: 66.66666667%;
   }
   .col-lg-pull-7 {
     right: 58.33333333%;
   }
   .col-lg-pull-6 {
     right: 50%;
   }
   .col-lg-pull-5 {
     right: 41.66666667%;
   }
   .col-lg-pull-4 {
     right: 33.33333333%;
   }
   .col-lg-pull-3 {
     right: 25%;
   }
   .col-lg-pull-2 {
     right: 16.66666667%;
   }
   .col-lg-pull-1 {
     right: 8.33333333%;
   }
   .col-lg-pull-0 {
     right: auto;
   }
   .col-lg-push-12 {
     left: 100%;
   }
   .col-lg-push-11 {
     left: 91.66666667%;
   }
   .col-lg-push-10 {
     left: 83.33333333%;
   }
   .col-lg-push-9 {
     left: 75%;
   }
   .col-lg-push-8 {
     left: 66.66666667%;
   }
   .col-lg-push-7 {
     left: 58.33333333%;
   }
   .col-lg-push-6 {
     left: 50%;
   }
   .col-lg-push-5 {
     left: 41.66666667%;
   }
   .col-lg-push-4 {
     left: 33.33333333%;
   }
   .col-lg-push-3 {
     left: 25%;
   }
   .col-lg-push-2 {
     left: 16.66666667%;
   }
   .col-lg-push-1 {
     left: 8.33333333%;
   }
   .col-lg-push-0 {
     left: auto;
   }
   .col-lg-offset-12 {
     margin-left: 100%;
   }
   .col-lg-offset-11 {
     margin-left: 91.66666667%;
   }
   .col-lg-offset-10 {
     margin-left: 83.33333333%;
   }
   .col-lg-offset-9 {
     margin-left: 75%;
   }
   .col-lg-offset-8 {
     margin-left: 66.66666667%;
   }
   .col-lg-offset-7 {
     margin-left: 58.33333333%;
   }
   .col-lg-offset-6 {
     margin-left: 50%;
   }
   .col-lg-offset-5 {
     margin-left: 41.66666667%;
   }
   .col-lg-offset-4 {
     margin-left: 33.33333333%;
   }
   .col-lg-offset-3 {
     margin-left: 25%;
   }
   .col-lg-offset-2 {
     margin-left: 16.66666667%;
   }
   .col-lg-offset-1 {
     margin-left: 8.33333333%;
   }
   .col-lg-offset-0 {
     margin-left: 0%;
   }
 }
 
 .clearfix,
 .clearfix:before,
 .clearfix:after,
 .container:before,
 .container:after,
 .container-fluid:before,
 .container-fluid:after,
 .row:before,
 .row:after {
   content: " ";
   display: table;
 }
 
 .clearfix:after,
 .container:after,
 .container-fluid:after,
 .row:after {
   clear: both;
 }
 
 .center-block {
   display: block;
   margin-left: auto;
   margin-right: auto;
 }
 
 .pull-right {
   float: right !important;
 }
 
 .pull-left {
   float: left !important;
 }
 
 *,
 *:before,
 *:after {
   -webkit-box-sizing: border-box;
           box-sizing: border-box;
 }
 
 /**
  *
  *  Responsive Embed
  *
  *  @package gleesik_module
  *
  **/
 .embed-responsive {
   position: relative;
   display: block;
   height: 0;
   padding: 0;
   overflow: hidden;
 }
 
 .embed-responsive .embed-responsive-item,
 .embed-responsive iframe,
 .embed-responsive embed,
 .embed-responsive object,
 .embed-responsive video {
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   height: 100%;
   width: 100%;
   border: 0;
 }
 
 .embed-responsive-16by9 {
   padding-bottom: 56.25%;
 }
 
 .embed-responsive-4by3 {
   padding-bottom: 75%;
 }
 
 /**
  *
  *  Animations Style
  *
  *  @package gleesik_modules
  *
  **/
 @-webkit-keyframes sera-pulse {
   0% {
     -webkit-transform: rotate(45deg) scale(1.1);
             transform: rotate(45deg) scale(1.1);
   }
   50% {
     -webkit-transform: rotate(45deg) scale(1.4);
             transform: rotate(45deg) scale(1.4);
   }
   100% {
     -webkit-transform: rotate(45deg) scale(1.1);
             transform: rotate(45deg) scale(1.1);
   }
 }
 @keyframes sera-pulse {
   0% {
     -webkit-transform: rotate(45deg) scale(1.1);
             transform: rotate(45deg) scale(1.1);
   }
   50% {
     -webkit-transform: rotate(45deg) scale(1.4);
             transform: rotate(45deg) scale(1.4);
   }
   100% {
     -webkit-transform: rotate(45deg) scale(1.1);
             transform: rotate(45deg) scale(1.1);
   }
 }
 
 @-webkit-keyframes sera-loader-in {
   0% {
     -webkit-transform: scale(0);
             transform: scale(0);
   }
   100% {
     -webkit-transform: scale(1);
             transform: scale(1);
   }
 }
 
 @keyframes sera-loader-in {
   0% {
     -webkit-transform: scale(0);
             transform: scale(0);
   }
   100% {
     -webkit-transform: scale(1);
             transform: scale(1);
   }
 }
 
 @-webkit-keyframes sera-loader-out {
   0% {
     -webkit-transform: scale(1);
             transform: scale(1);
   }
   100% {
     -webkit-transform: scale(0);
             transform: scale(0);
   }
 }
 
 @keyframes sera-loader-out {
   0% {
     -webkit-transform: scale(1);
             transform: scale(1);
   }
   100% {
     -webkit-transform: scale(0);
             transform: scale(0);
   }
 }
 
 @-webkit-keyframes sera-inM {
   50% {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(45deg);
             transform: rotate(45deg);
   }
 }
 
 @keyframes sera-inM {
   50% {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(45deg);
             transform: rotate(45deg);
   }
 }
 
 @-webkit-keyframes sera-outM {
   50% {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(45deg);
             transform: rotate(45deg);
   }
 }
 
 @keyframes sera-outM {
   50% {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(45deg);
             transform: rotate(45deg);
   }
 }
 
 @-webkit-keyframes sera-inT {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(14px) rotate(0deg);
             transform: translateY(14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(14px) rotate(135deg);
             transform: translateY(14px) rotate(135deg);
   }
 }
 
 @keyframes sera-inT {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(14px) rotate(0deg);
             transform: translateY(14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(14px) rotate(135deg);
             transform: translateY(14px) rotate(135deg);
   }
 }
 
 @-webkit-keyframes sera-outT {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(14px) rotate(0deg);
             transform: translateY(14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(14px) rotate(135deg);
             transform: translateY(14px) rotate(135deg);
   }
 }
 
 @keyframes sera-outT {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(14px) rotate(0deg);
             transform: translateY(14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(14px) rotate(135deg);
             transform: translateY(14px) rotate(135deg);
   }
 }
 
 @-webkit-keyframes sera-inBtm {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(-14px) rotate(0deg);
             transform: translateY(-14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(-14px) rotate(135deg);
             transform: translateY(-14px) rotate(135deg);
   }
 }
 
 @keyframes sera-inBtm {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(-14px) rotate(0deg);
             transform: translateY(-14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(-14px) rotate(135deg);
             transform: translateY(-14px) rotate(135deg);
   }
 }
 
 @-webkit-keyframes sera-outBtm {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(-14px) rotate(0deg);
             transform: translateY(-14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(-14px) rotate(135deg);
             transform: translateY(-14px) rotate(135deg);
   }
 }
 
 @keyframes sera-outBtm {
   0% {
     -webkit-transform: translateY(0px) rotate(0deg);
             transform: translateY(0px) rotate(0deg);
   }
   50% {
     -webkit-transform: translateY(-14px) rotate(0deg);
             transform: translateY(-14px) rotate(0deg);
   }
   100% {
     -webkit-transform: translateY(-14px) rotate(135deg);
             transform: translateY(-14px) rotate(135deg);
   }
 }
 
 /**
  *
  *  Owl Carousel Style
  *
  *  @package gleesik_modules
  *
  **/
 /* display none until init */
 .owl-carousel {
   display: none;
   position: relative;
   /* mouse grab icon */
   /* fix */
 }
 
 .owl-carousel .owl-wrapper-outer {
   overflow: hidden;
   position: relative;
   width: 100%;
 }
 
 .owl-carousel .owl-wrapper-outer.autoHeight {
   -webkit-transition: height 500ms ease-in-out;
   transition: height 500ms ease-in-out;
 }
 
 .owl-carousel .owl-wrapper-outer .owl-wrapper {
   display: none;
   position: relative;
   -webkit-transform: translate3d(0px, 0px, 0px);
   /* clearfix */
 }
 
 .owl-carousel .owl-wrapper-outer .owl-wrapper:after {
   content: ".";
   display: block;
   clear: both;
   visibility: hidden;
   line-height: 0;
   height: 0;
 }
 
 .owl-carousel .owl-item {
   float: left;
 }
 
 .owl-carousel .owl-controls {
   -webkit-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
   -webkit-tap-highlight-color: transparent;
 }
 
 .owl-carousel .owl-controls .owl-page,
 .owl-carousel .owl-controls .owl-buttons div {
   cursor: pointer;
 }
 
 .owl-carousel .grabbing {
   cursor: move;
 }
 
 .owl-carousel .owl-wrapper,
 .owl-carousel .owl-item {
   -webkit-backface-visibility: hidden;
           backface-visibility: hidden;
   -webkit-transform: translate3d(0, 0, 0);
           transform: translate3d(0, 0, 0);
 }
 
 @font-face {
   font-family: '7-stroke';
   src: url("../fonts/Pe-icon-7-stroke/Pe-icon-7-stroke.eot?d7yf1v");
   src: url("../fonts/Pe-icon-7-stroke/Pe-icon-7-stroke.eot?#iefixd7yf1v") format("embedded-opentype"), url("../fonts/Pe-icon-7-stroke/Pe-icon-7-stroke.woff?d7yf1v") format("woff"), url("../fonts/Pe-icon-7-stroke/Pe-icon-7-stroke.ttf?d7yf1v") format("truetype"), url("../fonts/Pe-icon-7-stroke/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke") format("svg");
   font-weight: normal;
   font-style: normal;
 }
 
 [class^="pe-7s-"], [class*=" pe-7s-"] {
   display: inline-block;
   font-family: '7-stroke';
   speak: none;
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   text-transform: none;
   line-height: 1;
   /* Better Font Rendering =========== */
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }
 
 .pe-7s-album:before {
   content: "\e6aa";
 }
 
 .pe-7s-arc:before {
   content: "\e6ab";
 }
 
 .pe-7s-back-2:before {
   content: "\e6ac";
 }
 
 .pe-7s-bandaid:before {
   content: "\e6ad";
 }
 
 .pe-7s-car:before {
   content: "\e6ae";
 }
 
 .pe-7s-diamond:before {
   content: "\e6af";
 }
 
 .pe-7s-door-lock:before {
   content: "\e6b0";
 }
 
 .pe-7s-eyedropper:before {
   content: "\e6b1";
 }
 
 .pe-7s-female:before {
   content: "\e6b2";
 }
 
 .pe-7s-gym:before {
   content: "\e6b3";
 }
 
 .pe-7s-hammer:before {
   content: "\e6b4";
 }
 
 .pe-7s-headphones:before {
   content: "\e6b5";
 }
 
 .pe-7s-helm:before {
   content: "\e6b6";
 }
 
 .pe-7s-hourglass:before {
   content: "\e6b7";
 }
 
 .pe-7s-leaf:before {
   content: "\e6b8";
 }
 
 .pe-7s-magic-wand:before {
   content: "\e6b9";
 }
 
 .pe-7s-male:before {
   content: "\e6ba";
 }
 
 .pe-7s-map-2:before {
   content: "\e6bb";
 }
 
 .pe-7s-next-2:before {
   content: "\e6bc";
 }
 
 .pe-7s-paint-bucket:before {
   content: "\e6bd";
 }
 
 .pe-7s-pendrive:before {
   content: "\e6be";
 }
 
 .pe-7s-photo:before {
   content: "\e6bf";
 }
 
 .pe-7s-piggy:before {
   content: "\e6c0";
 }
 
 .pe-7s-plugin:before {
   content: "\e6c1";
 }
 
 .pe-7s-refresh-2:before {
   content: "\e6c2";
 }
 
 .pe-7s-rocket:before {
   content: "\e6c3";
 }
 
 .pe-7s-settings:before {
   content: "\e6c4";
 }
 
 .pe-7s-shield:before {
   content: "\e6c5";
 }
 
 .pe-7s-smile:before {
   content: "\e6c6";
 }
 
 .pe-7s-usb:before {
   content: "\e6c7";
 }
 
 .pe-7s-vector:before {
   content: "\e6c8";
 }
 
 .pe-7s-wine:before {
   content: "\e6c9";
 }
 
 .pe-7s-cloud-upload:before {
   content: "\e68a";
 }
 
 .pe-7s-cash:before {
   content: "\e68c";
 }
 
 .pe-7s-close:before {
   content: "\e680";
 }
 
 .pe-7s-bluetooth:before {
   content: "\e68d";
 }
 
 .pe-7s-cloud-download:before {
   content: "\e68b";
 }
 
 .pe-7s-way:before {
   content: "\e68e";
 }
 
 .pe-7s-close-circle:before {
   content: "\e681";
 }
 
 .pe-7s-id:before {
   content: "\e68f";
 }
 
 .pe-7s-angle-up:before {
   content: "\e682";
 }
 
 .pe-7s-wristwatch:before {
   content: "\e690";
 }
 
 .pe-7s-angle-up-circle:before {
   content: "\e683";
 }
 
 .pe-7s-world:before {
   content: "\e691";
 }
 
 .pe-7s-angle-right:before {
   content: "\e684";
 }
 
 .pe-7s-volume:before {
   content: "\e692";
 }
 
 .pe-7s-angle-right-circle:before {
   content: "\e685";
 }
 
 .pe-7s-users:before {
   content: "\e693";
 }
 
 .pe-7s-angle-left:before {
   content: "\e686";
 }
 
 .pe-7s-user-female:before {
   content: "\e694";
 }
 
 .pe-7s-angle-left-circle:before {
   content: "\e687";
 }
 
 .pe-7s-up-arrow:before {
   content: "\e695";
 }
 
 .pe-7s-angle-down:before {
   content: "\e688";
 }
 
 .pe-7s-switch:before {
   content: "\e696";
 }
 
 .pe-7s-angle-down-circle:before {
   content: "\e689";
 }
 
 .pe-7s-scissors:before {
   content: "\e697";
 }
 
 .pe-7s-wallet:before {
   content: "\e600";
 }
 
 .pe-7s-safe:before {
   content: "\e698";
 }
 
 .pe-7s-volume2:before {
   content: "\e601";
 }
 
 .pe-7s-volume1:before {
   content: "\e602";
 }
 
 .pe-7s-voicemail:before {
   content: "\e603";
 }
 
 .pe-7s-video:before {
   content: "\e604";
 }
 
 .pe-7s-user:before {
   content: "\e605";
 }
 
 .pe-7s-upload:before {
   content: "\e606";
 }
 
 .pe-7s-unlock:before {
   content: "\e607";
 }
 
 .pe-7s-umbrella:before {
   content: "\e608";
 }
 
 .pe-7s-trash:before {
   content: "\e609";
 }
 
 .pe-7s-tools:before {
   content: "\e60a";
 }
 
 .pe-7s-timer:before {
   content: "\e60b";
 }
 
 .pe-7s-ticket:before {
   content: "\e60c";
 }
 
 .pe-7s-target:before {
   content: "\e60d";
 }
 
 .pe-7s-sun:before {
   content: "\e60e";
 }
 
 .pe-7s-study:before {
   content: "\e60f";
 }
 
 .pe-7s-stopwatch:before {
   content: "\e610";
 }
 
 .pe-7s-star:before {
   content: "\e611";
 }
 
 .pe-7s-speaker:before {
   content: "\e612";
 }
 
 .pe-7s-signal:before {
   content: "\e613";
 }
 
 .pe-7s-shuffle:before {
   content: "\e614";
 }
 
 .pe-7s-shopbag:before {
   content: "\e615";
 }
 
 .pe-7s-share:before {
   content: "\e616";
 }
 
 .pe-7s-server:before {
   content: "\e617";
 }
 
 .pe-7s-search:before {
   content: "\e618";
 }
 
 .pe-7s-film:before {
   content: "\e6a5";
 }
 
 .pe-7s-science:before {
   content: "\e619";
 }
 
 .pe-7s-disk:before {
   content: "\e6a6";
 }
 
 .pe-7s-ribbon:before {
   content: "\e61a";
 }
 
 .pe-7s-repeat:before {
   content: "\e61b";
 }
 
 .pe-7s-refresh:before {
   content: "\e61c";
 }
 
 .pe-7s-add-user:before {
   content: "\e6a9";
 }
 
 .pe-7s-refresh-cloud:before {
   content: "\e61d";
 }
 
 .pe-7s-paperclip:before {
   content: "\e69c";
 }
 
 .pe-7s-radio:before {
   content: "\e61e";
 }
 
 .pe-7s-note2:before {
   content: "\e69d";
 }
 
 .pe-7s-print:before {
   content: "\e61f";
 }
 
 .pe-7s-network:before {
   content: "\e69e";
 }
 
 .pe-7s-prev:before {
   content: "\e620";
 }
 
 .pe-7s-mute:before {
   content: "\e69f";
 }
 
 .pe-7s-power:before {
   content: "\e621";
 }
 
 .pe-7s-medal:before {
   content: "\e6a0";
 }
 
 .pe-7s-portfolio:before {
   content: "\e622";
 }
 
 .pe-7s-like2:before {
   content: "\e6a1";
 }
 
 .pe-7s-plus:before {
   content: "\e623";
 }
 
 .pe-7s-left-arrow:before {
   content: "\e6a2";
 }
 
 .pe-7s-play:before {
   content: "\e624";
 }
 
 .pe-7s-key:before {
   content: "\e6a3";
 }
 
 .pe-7s-plane:before {
   content: "\e625";
 }
 
 .pe-7s-joy:before {
   content: "\e6a4";
 }
 
 .pe-7s-photo-gallery:before {
   content: "\e626";
 }
 
 .pe-7s-pin:before {
   content: "\e69b";
 }
 
 .pe-7s-phone:before {
   content: "\e627";
 }
 
 .pe-7s-plug:before {
   content: "\e69a";
 }
 
 .pe-7s-pen:before {
   content: "\e628";
 }
 
 .pe-7s-right-arrow:before {
   content: "\e699";
 }
 
 .pe-7s-paper-plane:before {
   content: "\e629";
 }
 
 .pe-7s-delete-user:before {
   content: "\e6a7";
 }
 
 .pe-7s-paint:before {
   content: "\e62a";
 }
 
 .pe-7s-bottom-arrow:before {
   content: "\e6a8";
 }
 
 .pe-7s-notebook:before {
   content: "\e62b";
 }
 
 .pe-7s-note:before {
   content: "\e62c";
 }
 
 .pe-7s-next:before {
   content: "\e62d";
 }
 
 .pe-7s-news-paper:before {
   content: "\e62e";
 }
 
 .pe-7s-musiclist:before {
   content: "\e62f";
 }
 
 .pe-7s-music:before {
   content: "\e630";
 }
 
 .pe-7s-mouse:before {
   content: "\e631";
 }
 
 .pe-7s-more:before {
   content: "\e632";
 }
 
 .pe-7s-moon:before {
   content: "\e633";
 }
 
 .pe-7s-monitor:before {
   content: "\e634";
 }
 
 .pe-7s-micro:before {
   content: "\e635";
 }
 
 .pe-7s-menu:before {
   content: "\e636";
 }
 
 .pe-7s-map:before {
   content: "\e637";
 }
 
 .pe-7s-map-marker:before {
   content: "\e638";
 }
 
 .pe-7s-mail:before {
   content: "\e639";
 }
 
 .pe-7s-mail-open:before {
   content: "\e63a";
 }
 
 .pe-7s-mail-open-file:before {
   content: "\e63b";
 }
 
 .pe-7s-magnet:before {
   content: "\e63c";
 }
 
 .pe-7s-loop:before {
   content: "\e63d";
 }
 
 .pe-7s-look:before {
   content: "\e63e";
 }
 
 .pe-7s-lock:before {
   content: "\e63f";
 }
 
 .pe-7s-lintern:before {
   content: "\e640";
 }
 
 .pe-7s-link:before {
   content: "\e641";
 }
 
 .pe-7s-like:before {
   content: "\e642";
 }
 
 .pe-7s-light:before {
   content: "\e643";
 }
 
 .pe-7s-less:before {
   content: "\e644";
 }
 
 .pe-7s-keypad:before {
   content: "\e645";
 }
 
 .pe-7s-junk:before {
   content: "\e646";
 }
 
 .pe-7s-info:before {
   content: "\e647";
 }
 
 .pe-7s-home:before {
   content: "\e648";
 }
 
 .pe-7s-help2:before {
   content: "\e649";
 }
 
 .pe-7s-help1:before {
   content: "\e64a";
 }
 
 .pe-7s-graph3:before {
   content: "\e64b";
 }
 
 .pe-7s-graph2:before {
   content: "\e64c";
 }
 
 .pe-7s-graph1:before {
   content: "\e64d";
 }
 
 .pe-7s-graph:before {
   content: "\e64e";
 }
 
 .pe-7s-global:before {
   content: "\e64f";
 }
 
 .pe-7s-gleam:before {
   content: "\e650";
 }
 
 .pe-7s-glasses:before {
   content: "\e651";
 }
 
 .pe-7s-gift:before {
   content: "\e652";
 }
 
 .pe-7s-folder:before {
   content: "\e653";
 }
 
 .pe-7s-flag:before {
   content: "\e654";
 }
 
 .pe-7s-filter:before {
   content: "\e655";
 }
 
 .pe-7s-file:before {
   content: "\e656";
 }
 
 .pe-7s-expand1:before {
   content: "\e657";
 }
 
 .pe-7s-exapnd2:before {
   content: "\e658";
 }
 
 .pe-7s-edit:before {
   content: "\e659";
 }
 
 .pe-7s-drop:before {
   content: "\e65a";
 }
 
 .pe-7s-drawer:before {
   content: "\e65b";
 }
 
 .pe-7s-download:before {
   content: "\e65c";
 }
 
 .pe-7s-display2:before {
   content: "\e65d";
 }
 
 .pe-7s-display1:before {
   content: "\e65e";
 }
 
 .pe-7s-diskette:before {
   content: "\e65f";
 }
 
 .pe-7s-date:before {
   content: "\e660";
 }
 
 .pe-7s-cup:before {
   content: "\e661";
 }
 
 .pe-7s-culture:before {
   content: "\e662";
 }
 
 .pe-7s-crop:before {
   content: "\e663";
 }
 
 .pe-7s-credit:before {
   content: "\e664";
 }
 
 .pe-7s-copy-file:before {
   content: "\e665";
 }
 
 .pe-7s-config:before {
   content: "\e666";
 }
 
 .pe-7s-compass:before {
   content: "\e667";
 }
 
 .pe-7s-comment:before {
   content: "\e668";
 }
 
 .pe-7s-coffee:before {
   content: "\e669";
 }
 
 .pe-7s-cloud:before {
   content: "\e66a";
 }
 
 .pe-7s-clock:before {
   content: "\e66b";
 }
 
 .pe-7s-check:before {
   content: "\e66c";
 }
 
 .pe-7s-chat:before {
   content: "\e66d";
 }
 
 .pe-7s-cart:before {
   content: "\e66e";
 }
 
 .pe-7s-camera:before {
   content: "\e66f";
 }
 
 .pe-7s-call:before {
   content: "\e670";
 }
 
 .pe-7s-calculator:before {
   content: "\e671";
 }
 
 .pe-7s-browser:before {
   content: "\e672";
 }
 
 .pe-7s-box2:before {
   content: "\e673";
 }
 
 .pe-7s-box1:before {
   content: "\e674";
 }
 
 .pe-7s-bookmarks:before {
   content: "\e675";
 }
 
 .pe-7s-bicycle:before {
   content: "\e676";
 }
 
 .pe-7s-bell:before {
   content: "\e677";
 }
 
 .pe-7s-battery:before {
   content: "\e678";
 }
 
 .pe-7s-ball:before {
   content: "\e679";
 }
 
 .pe-7s-back:before {
   content: "\e67a";
 }
 
 .pe-7s-attention:before {
   content: "\e67b";
 }
 
 .pe-7s-anchor:before {
   content: "\e67c";
 }
 
 .pe-7s-albums:before {
   content: "\e67d";
 }
 
 .pe-7s-alarm:before {
   content: "\e67e";
 }
 
 .pe-7s-airplay:before {
   content: "\e67f";
 }
 
 .pe-lg {
   font-size: 1.3333333333333333em;
   line-height: 0.75em;
   vertical-align: -15%;
 }
 
 .pe-2x {
   font-size: 2em;
 }
 
 .pe-3x {
   font-size: 3em;
 }
 
 .pe-4x {
   font-size: 4em;
 }
 
 .pe-5x {
   font-size: 5em;
 }
 
 .pe-fw {
   width: 1.2857142857142858em;
   text-align: center;
 }
 
 .pe-ul {
   padding-left: 0;
   margin-left: 2.142857142857143em;
   list-style-type: none;
 }
 
 .pe-ul > li {
   position: relative;
 }
 
 .pe-li {
   position: absolute;
   left: -2.142857142857143em;
   width: 2.142857142857143em;
   top: 0.14285714285714285em;
   text-align: center;
 }
 
 .pe-li.pe-lg {
   left: -1.8571428571428572em;
 }
 
 .pe-border {
   padding: .2em .25em .15em;
   border: solid 0.08em #eeeeee;
   -webkit-border-radius: .1em;
           border-radius: .1em;
 }
 
 .pull-right {
   float: right;
 }
 
 .pull-left {
   float: left;
 }
 
 .pe.pull-left {
   margin-right: .3em;
 }
 
 .pe.pull-right {
   margin-left: .3em;
 }
 
 .pe-spin {
   -webkit-animation: spin 2s infinite linear;
           animation: spin 2s infinite linear;
 }
 
 @-webkit-keyframes spin {
   0% {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(359deg);
             transform: rotate(359deg);
   }
 }
 
 @keyframes spin {
   0% {
     -webkit-transform: rotate(0deg);
             transform: rotate(0deg);
   }
   100% {
     -webkit-transform: rotate(359deg);
             transform: rotate(359deg);
   }
 }
 
 .pe-rotate-90 {
   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
   -webkit-transform: rotate(90deg);
       -ms-transform: rotate(90deg);
           transform: rotate(90deg);
 }
 
 .pe-rotate-180 {
   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
   -webkit-transform: rotate(180deg);
       -ms-transform: rotate(180deg);
           transform: rotate(180deg);
 }
 
 .pe-rotate-270 {
   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
   -webkit-transform: rotate(270deg);
       -ms-transform: rotate(270deg);
           transform: rotate(270deg);
 }
 
 .pe-flip-horizontal {
   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
   -webkit-transform: scale(-1, 1);
       -ms-transform: scale(-1, 1);
           transform: scale(-1, 1);
 }
 
 .pe-flip-vertical {
   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
   -webkit-transform: scale(1, -1);
       -ms-transform: scale(1, -1);
           transform: scale(1, -1);
 }
 
 .pe-stack {
   position: relative;
   display: inline-block;
   width: 2em;
   height: 2em;
   line-height: 2em;
   vertical-align: middle;
 }
 
 .pe-stack-1x,
 .pe-stack-2x {
   position: absolute;
   left: 0;
   width: 100%;
   text-align: center;
 }
 
 .pe-stack-1x {
   line-height: inherit;
 }
 
 .pe-stack-2x {
   font-size: 2em;
 }
 
 .pe-inverse {
   color: #ffffff;
 }
 
 /* Custom classes / mods - PIXEDEN */
 .pe-va {
   vertical-align: middle;
 }
 
 .pe-border {
   border: solid 0.08em #eaeaea;
 }
 
 [class^="pe-7s-"], [class*=" pe-7s-"] {
   display: inline-block;
 }
 
 /*
   Flaticon icon font: Flaticon Social Media Icons
 */
 @font-face {
   font-family: "Flaticon";
   src: url("../fonts/Flaticon/Flaticon.eot");
   src: url("../fonts/Flaticon/Flaticon.eot?#iefix") format("embedded-opentype"), url("../fonts/Flaticon/Flaticon.woff") format("woff"), url("../fonts/Flaticon/Flaticon.ttf") format("truetype"), url("../fonts/Flaticon/Flaticon.svg#Flaticon") format("svg");
   font-weight: normal;
   font-style: normal;
 }
 
 @media screen and (-webkit-min-device-pixel-ratio: 0) {
   @font-face {
     font-family: "Flaticon";
     src: url("../fonts/Flaticon/Flaticon.svg#Flaticon") format("svg");
   }
 }
 
 .fi:before {
   display: inline-block;
   font-family: "Flaticon";
   font-style: normal;
   font-weight: normal;
   font-variant: normal;
   line-height: 1;
   text-decoration: inherit;
   text-rendering: optimizeLegibility;
   text-transform: none;
   -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
   font-smoothing: antialiased;
 }
 
 .flaticon-badoo:before {
   content: "\f100";
 }
 
 .flaticon-bebo:before {
   content: "\f101";
 }
 
 .flaticon-behance:before {
   content: "\f102";
 }
 
 .flaticon-blogger:before {
   content: "\f103";
 }
 
 .flaticon-deviantart:before {
   content: "\f104";
 }
 
 .flaticon-digg:before {
   content: "\f105";
 }
 
 .flaticon-disqus:before {
   content: "\f106";
 }
 
 .flaticon-dribbble:before {
   content: "\f107";
 }
 
 .flaticon-facebook:before {
   content: "\f108";
 }
 
 .flaticon-flickr:before {
   content: "\f109";
 }
 
 .flaticon-forrst:before {
   content: "\f10a";
 }
 
 .flaticon-google-plus:before {
   content: "\f10b";
 }
 
 .flaticon-instagram:before {
   content: "\f10c";
 }
 
 .flaticon-line:before {
   content: "\f10d";
 }
 
 .flaticon-linkedin:before {
   content: "\f10e";
 }
 
 .flaticon-myspace:before {
   content: "\f10f";
 }
 
 .flaticon-pinterest:before {
   content: "\f110";
 }
 
 .flaticon-plurk:before {
   content: "\f111";
 }
 
 .flaticon-rss:before {
   content: "\f112";
 }
 
 .flaticon-skype:before {
   content: "\f113";
 }
 
 .flaticon-telegram:before {
   content: "\f114";
 }
 
 .flaticon-tumblr:before {
   content: "\f115";
 }
 
 .flaticon-twitter:before {
   content: "\f116";
 }
 
 .flaticon-viber:before {
   content: "\f117";
 }
 
 .flaticon-vimeo:before {
   content: "\f118";
 }
 
 .flaticon-vine:before {
   content: "\f119";
 }
 
 .flaticon-vk:before {
   content: "\f11a";
 }
 
 .flaticon-whatsapp:before {
   content: "\f11b";
 }
 
 .flaticon-xing:before {
   content: "\f11c";
 }
 
 .flaticon-youtube:before {
   content: "\f11d";
 }
 
 /**
  *
  *  Forms & Inputs Style
  *
  *  @package gleesik_components
  *
  **/
 form {
   display: block;
   position: relative;
   width: 100%;
 }
 
 input, textarea, button {
   display: inline-block;
   position: relative;
   width: 100%;
   height: auto;
   font-size: 1rem;
   font-family: "Lato";
   padding: 15px;
   outline: 0;
   color: #5e5e5e;
   border: 1px solid #bdc3c7;
   background: rgba(255, 255, 255, 0.1);
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, button::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
   color: #bdc3c7;
   -webkit-transition: color 300ms ease-in-out;
   transition: color 300ms ease-in-out;
 }
 
 input::-moz-placeholder, textarea::-moz-placeholder, button::-moz-placeholder {
   /* Firefox 19+ */
   color: #bdc3c7;
   -webkit-transition: color 300ms ease-in-out;
   transition: color 300ms ease-in-out;
 }
 
 input:-ms-input-placeholder, textarea:-ms-input-placeholder, button:-ms-input-placeholder {
   /* IE 10+ */
   color: #bdc3c7;
   -webkit-transition: color 300ms ease-in-out;
   transition: color 300ms ease-in-out;
 }
 
 input:-moz-placeholder, textarea:-moz-placeholder, button:-moz-placeholder {
   /* Firefox 18- */
   color: #bdc3c7;
   -webkit-transition: color 300ms ease-in-out;
   transition: color 300ms ease-in-out;
 }
 
 input:hover, input:active, textarea:hover, textarea:active, button:hover, button:active {
   color: #5d5d5d;
   border-color: #5d5d5d;
 }
 
 input:hover::-webkit-input-placeholder, input:active::-webkit-input-placeholder, textarea:hover::-webkit-input-placeholder, textarea:active::-webkit-input-placeholder, button:hover::-webkit-input-placeholder, button:active::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
   color: #5d5d5d;
 }
 
 input:hover::-moz-placeholder, input:active::-moz-placeholder, textarea:hover::-moz-placeholder, textarea:active::-moz-placeholder, button:hover::-moz-placeholder, button:active::-moz-placeholder {
   /* Firefox 19+ */
   color: #5d5d5d;
 }
 
 input:hover:-ms-input-placeholder, input:active:-ms-input-placeholder, textarea:hover:-ms-input-placeholder, textarea:active:-ms-input-placeholder, button:hover:-ms-input-placeholder, button:active:-ms-input-placeholder {
   /* IE 10+ */
   color: #5d5d5d;
 }
 
 input:hover:-moz-placeholder, input:active:-moz-placeholder, textarea:hover:-moz-placeholder, textarea:active:-moz-placeholder, button:hover:-moz-placeholder, button:active:-moz-placeholder {
   /* Firefox 18- */
   color: #5d5d5d;
 }
 
 /**
  *
  *  Social Icons Style
  *
  *  @package gleesik_components
  *
  **/
 .social-icons {
   display: inline-block;
   font-size: 1.35rem;
 }
 
 .social-icons ul.icons.global-text-color-icons li a {
   color: #5e5e5e;
 }
 
 .social-icons ul.icons.global-text-color-icons li a:hover {
   color: #75797a;
 }
 
 .social-icons ul.icons li {
   float: left;
   margin-right: 10px;
 }
 
 .social-icons ul.icons li:last-child {
   margin-right: 0;
 }
 
 /**
  *
  *  Tags Box Style
  *
  *  @package gleesik_components
  *
  **/
 ul.tag-cloud-box li {
   display: inline-block;
   float: left;
   margin: 0 10px 10px 0;
 }
 
 ul.tag-cloud-box li a {
   display: block;
   padding: 8px 12px;
   color: #bdc3c7;
   border: 1px solid #bdc3c7;
 }
 
 ul.tag-cloud-box li a:hover {
   color: #fff;
   border-color: #21c2f8;
   background: #21c2f8;
 }
 
 /**
  *
  *  Buttons Style
  *
  *  @package gleesik_components
  *
  **/
 .button, button {
   display: inline-block;
   width: auto;
   max-width: 100%;
   color: #21c2f8;
   border: 2px solid #21c2f8;
   padding: 0.8em 2em;
   cursor: pointer;
   font-size: 1rem;
   font-weight: bold;
   text-align: center;
 }
 
 .button.button-focus, button.button-focus {
   color: #fff;
   background-color: #21c2f8;
 }
 
 .button:hover, .button:active, button:hover, button:active {
   color: #fff;
   border-color: #84ddfb;
   background-color: #84ddfb;
 }
 
 .button-cta {
   display: inline-block;
   max-width: 100%;
   color: #fff;
   border: 2px solid #fff;
   padding: 1em 1.5em;
   font-size: 1.25rem;
   font-weight: bold;
   margin-bottom: 10px;
   text-align: center;
   text-transform: uppercase;
 }
 
 .button-right {
   /* override this attribute from .button botton{} */
   float: right;
 }
 
 .button-cta.button-focus {
   border-color: #21c2f8;
   background-color: #21c2f8;
 }
 
 .button-cta.button-blue {
   color: #21c2f8;
   border-color: #21c2f8;
 }
 
 .button-cta:hover, .button-cta:active {
   color: #fff;
   border-color: #84ddfb;
   background-color: #84ddfb;
 }
 
 button.button-block,
 .button.button-block,
 .button-cta.button-block {
   display: block;
   width: 100%;
 }
 
 /**
  *
  *  Global Style
  *
  *  @package gleesik_layout
  *
  **/
 body {
   font-family: "Lato", Arial, sans-serif;
   font-size: 16px;
   font-weight: normal;
   letter-spacing: 0.08rem;
   color: #080808;
   background-color: #fff;
 }
 
 body .site-header .container:before {
   content: '';
   display: block;
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   width: 0;
   height: 0;
   background-color: transparent;
   -webkit-transition: background-color 300ms ease-in-out;
   transition: background-color 300ms ease-in-out;
   z-index: 99;
 }
 
 body.no-scroll {
   height: 100vh;
   overflow-y: hidden;
 }
 
 body.no-scroll .site-header .container:before {
   width: auto;
   height: auto;
   background-color: rgba(0, 0, 0, 0.9);
 }
 
 strong, b {
   font-weight: bold;
 }
 
 em, i {
   font-style: italic;
 }
 
 .thin {
   font-weight: lighter;
 }
 
 h1 {
   font-size: 3.75rem;
 }
 
 h2 {
   font-size: 2.1875rem;
 }
 
 h3 {
   font-size: 1.25rem;
 }
 
 h4 {
   font-size: 1.15rem;
 }
 
 h5 {
   font-size: 1rem;
 }
 
 h6 {
   font-size: 0.85rem;
 }
 
 p {
   line-height: 1.5;
   margin-bottom: 1em;
 }
 
 p:last-child {
   margin-bottom: 0;
 }
 
 hr {
   clear: both;
   border: none;
   width: 100%;
   height: 1px;
   background-color: #bdc3c7;
 }
 
 hr.separator {
   width: 60%;
 }
 
 hr.small {
   width: 40px;
   height: 2px;
   background-color: #21c2f8;
 }
 
 hr.medium {
   width: 70px;
   height: 1px;
   background-color: #21c2f8;
 }
 
 hr.normal {
   position: relative;
   border: none;
   width: 100px;
   height: 4px;
   background-color: #2c3e50;
 }
 
 hr.normal:after {
   display: block;
   position: absolute;
   content: "";
   width: 50%;
   height: 4px;
   background: #21c2f8;
   top: 0;
   right: 0;
 }
 
 a {
   color: #21c2f8;
   text-decoration: none;
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 a:hover, a:active {
   color: #84ddfb;
 }
 
 img {
   display: block;
   position: relative;
   max-width: 100%;
   height: auto !important;
 }
 
 blockquote {
   display: block;
   position: relative;
   width: auto;
   float: left;
   font-size: 2.2em;
   font-weight: lighter;
   line-height: 1.35;
   padding: 0 10%;
   margin: 1.2em auto 1em auto;
   z-index: 1;
 }
 
 blockquote small {
   display: block;
   font-size: 1.6rem;
   font-style: italic;
   margin-top: 3%;
   float: right;
 }
 
 blockquote:before {
   content: '\0022';
   position: absolute;
   font-size: 7em;
   font-weight: 900;
   opacity: 0.1;
   top: -6rem;
   left: 5%;
   z-index: -1;
 }
 
 @media (max-width: 450px) {
   blockquote {
     font-size: 2em;
   }
   blockquote small {
     font-size: 1.2rem;
   }
   blockquote:before {
     top: -3rem;
     left: 3%;
   }
 }
 
 .page-loader {
   display: block;
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   opacity: 1;
   background: #f4f8f9;
   -webkit-transition: all 0.7s ease-in-out;
   transition: all 0.7s ease-in-out;
   z-index: 99999999999;
 }
 
 .page-loader .load-icon {
   display: block;
   position: relative;
   width: 70px;
   height: 70px;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
       -ms-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
 }
 
 .page-loader .load-icon:before, .page-loader .load-icon:after {
   content: '';
   display: block;
   position: absolute;
   background: rgba(33, 194, 248, 0.2);
   width: 100%;
   height: 100%;
   -webkit-border-radius: 50%;
           border-radius: 50%;
 }
 
 .page-loader .load-icon:before {
   -webkit-animation: sera-loader-in .85s linear 0s alternate infinite;
           animation: sera-loader-in .85s linear 0s alternate infinite;
 }
 
 .page-loader .load-icon:after {
   -webkit-animation: sera-loader-out .85s linear 0s alternate infinite;
           animation: sera-loader-out .85s linear 0s alternate infinite;
 }
 
 .page-loader.load-complete {
   height: 0;
   opacity: 0;
 }
 
 .go-to-top-button {
   position: fixed;
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-align: center;
   -webkit-align-items: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
       -ms-flex-pack: center;
           justify-content: center;
   bottom: 20px;
   right: 20px;
   width: 50px;
   height: 50px;
   cursor: pointer;
   border: 1px solid #21c2f8;
   background: rgba(244, 248, 249, 0.2);
   -webkit-transform: translate3d(0, -webkit-calc(130% + 50px), 0);
           transform: translate3d(0, calc(130% + 50px), 0);
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
   z-index: 9999;
 }
 
 .go-to-top-button i {
   font-size: 3.2rem;
 }
 
 .go-to-top-button:hover {
   color: #fff;
   background: #21c2f8;
 }
 
 .go-to-top-button.active {
   -webkit-transform: translate3d(0, 0, 0);
           transform: translate3d(0, 0, 0);
 }
 
 /**
  *
  *  Header Style
  *
  *  @package gleesik_layout
  *
  **/
 header.site-header {
   position: relative;
   width: 100%;
   height: 100vh;
   max-height: 1080px;
   z-index: 1;
 }
 
 header.site-header.blog-header {
   height: 600px;
 }
 
 header.site-header.blog-header .parallax-wrapper {
   height: 600px;
 }
 
 header.site-header.blog-header .container {
   height: 600px;
 }
 
 header.site-header.blog-header .header-banner {
   height: -webkit-calc(600px - 250px);
   height: calc(600px - 250px);
 }
 
 header.site-header .parallax-wrapper {
   overflow: hidden;
   position: absolute;
   width: 100%;
   height: 100vh;
   max-height: 1080px;
   top: 0;
   left: 0;
 }
 
 header.site-header .container {
   position: relative;
   height: 100vh;
   max-height: 1080px;
 }
 
 header.site-header .site-navigation-bar {
   position: relative;
   height: 100px;
   min-height: 100px;
   z-index: 2;
 }
 
 header.site-header .site-navigation-bar .logo img {
   display: block;
   float: left;
 }
 
 header.site-header .site-navigation-bar .navigation-bar nav.nav-menu {
   position: relative;
   display: block;
   float: right;
 }
 
 header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li {
   float: left;
   margin-right: 30px;
 }
 
 header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li:last-child {
   margin-right: 0;
 }
 
 header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li.current-menu-item a {
   color: #21c2f8;
   position: relative;
 }
 
 header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li.current-menu-item a:before {
   content: '';
   display: block;
   position: absolute;
   width: 50%;
   height: 1px;
   left: 50%;
   bottom: -10px;
   -webkit-transform: translateX(-50%);
       -ms-transform: translateX(-50%);
           transform: translateX(-50%);
   background-color: #21c2f8;
 }
 
 header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li a {
   display: block;
   color: rgb(145, 143, 143);
   padding: 10px 0;
 }
 
 header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li a:hover, header.site-header .site-navigation-bar .navigation-bar nav.nav-menu ul li a:active {
   color: #21c2f8;
 }
 
 header.site-header .header-banner {
   height: -webkit-calc(100vh - 250px);
   height: calc(100vh - 250px);
   max-height: -webkit-calc(1080px - 250px);
   max-height: calc(1080px - 250px);
   z-index: 2;
   color: silver;
 }
 
 header.site-header .header-banner .heading-content {
   width: 100%;
   text-transform: uppercase;
 }
 
 header.site-header .header-banner .heading-content .heading-title hr.small {
   margin-top: 40px;
   margin-bottom: 40px;
 }
 
 header.site-header .header-banner .heading-content .buttons-header {
   margin-top: 40px;
 }
 
 header.site-header .header-banner .heading-content .buttons-header .button-cta {
   width: 100%;
 }
 
 header.site-header .arrow-down-section {
   position: absolute;
   width: 100%;
   height: 150px;
   bottom: 0;
   z-index: 3;
 }
 
 header.site-header .arrow-down-section .arrow-down {
   display: block;
   position: relative;
   font-size: 4rem;
   line-height: 4rem;
   color: #fff;
   width: 64px;
   height: 64px;
 }
 
 header.site-header .arrow-down-section .arrow-down:before, header.site-header .arrow-down-section .arrow-down:after {
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   -webkit-transform: rotate(45deg);
       -ms-transform: rotate(45deg);
           transform: rotate(45deg);
 }
 
 header.site-header .arrow-down-section .arrow-down:before {
   border: 1px solid #21c2f8;
   -webkit-animation: sera-pulse 2.5s ease-in-out infinite alternate;
           animation: sera-pulse 2.5s ease-in-out infinite alternate;
 }
 
 header.site-header .arrow-down-section .arrow-down:after {
   -webkit-transition: background-color 300ms ease-in-out;
   transition: background-color 300ms ease-in-out;
   background-color: rgba(255, 255, 255, 0.2);
 }
 
 header.site-header .arrow-down-section .arrow-down:hover i {
   -webkit-transform: scale(1.3);
       -ms-transform: scale(1.3);
           transform: scale(1.3);
 }
 
 header.site-header .arrow-down-section .arrow-down:hover:after {
   background-color: rgba(255, 255, 255, 0.3);
 }
 
 header.site-header .arrow-down-section .arrow-down i {
   width: 100%;
   height: 100%;
   text-align: center;
   -webkit-transition: -webkit-transform 300ms ease-in-out;
   transition: -webkit-transform 300ms ease-in-out;
   transition: transform 300ms ease-in-out;
   transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
 }
 
 header.site-header .arrow-down-section .arrow-down i:before {
   width: 100%;
   height: 100%;
 }
 
 /**
  *
  *  Responsive Header Style + Navigation Mobile Menu
  *
  *  @package gleesik_layout
  *
  **/
 .toggle-menu {
   display: none;
 }
 
 @media (max-width: 1000px) {
   header.site-header {
     width: 100%;
     overflow: visible !important;
     z-index: 99999;
   }
   header.site-header .header-banner {
     top: 60px;
     height: -webkit-calc(100vh - 190px);
     height: calc(100vh - 190px);
     max-height: -webkit-calc(1080px - 190px);
     max-height: calc(1080px - 190px);
   }
   header.site-header .header-banner .heading-content {
     padding: 0 40px;
   }
   header.site-header .header-banner .heading-content .heading-title hr.small {
     margin-top: 15px;
     margin-bottom: 15px;
   }
   header.site-header .site-navigation-bar {
     display: block;
     position: fixed;
     width: 100%;
     height: 60px;
     top: 0;
     left: 15px;
     min-height: 60px;
     background: #21c2f8;
     z-index: 99999999999999;
   }
   header.site-header .site-navigation-bar .navigation-bar {
     display: block;
     position: absolute;
     top: 60px;
     left: 0;
     width: 100%;
     height: -webkit-calc(100vh - 60px);
     height: calc(100vh - 60px);
     font-weight: lighter;
     overflow-y: scroll;
     opacity: 1;
     background: #202020;
     -webkit-transform: translateY(100%);
         -ms-transform: translateY(100%);
             transform: translateY(100%);
     -webkit-transition: -webkit-transform 0.8s ease-in-out;
     transition: -webkit-transform 0.8s ease-in-out;
     transition: transform 0.8s ease-in-out;
     transition: transform 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu {
     width: 100%;
     padding: 30px 0;
     font-size: 1.3rem;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu {
     position: relative;
     display: block;
     padding: 0px 48px;
     list-style: none;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li {
     width: 100%;
     border-bottom: 1px solid #464646;
     margin-bottom: 5px;
     opacity: 0;
     -webkit-transform: scale(1.15) translateY(-30px);
         -ms-transform: scale(1.15) translateY(-30px);
             transform: scale(1.15) translateY(-30px);
     -webkit-transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
     transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
     transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
     transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:last-child {
     border-bottom: 0;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(1) {
     -webkit-transition-delay: 0.49s;
             transition-delay: 0.49s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(2) {
     -webkit-transition-delay: 0.42s;
             transition-delay: 0.42s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(3) {
     -webkit-transition-delay: 0.35s;
             transition-delay: 0.35s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(4) {
     -webkit-transition-delay: 0.28s;
             transition-delay: 0.28s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(5) {
     -webkit-transition-delay: 0.21s;
             transition-delay: 0.21s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(6) {
     -webkit-transition-delay: 0.14s;
             transition-delay: 0.14s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(7) {
     -webkit-transition-delay: 0.07s;
             transition-delay: 0.07s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(8) {
     -webkit-transition-delay: 0s;
             transition-delay: 0s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(9) {
     -webkit-transition-delay: -0.07s;
             transition-delay: -0.07s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(10) {
     -webkit-transition-delay: -0.14s;
             transition-delay: -0.14s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(11) {
     -webkit-transition-delay: -0.21s;
             transition-delay: -0.21s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li:nth-child(12) {
     -webkit-transition-delay: -0.28s;
             transition-delay: -0.28s;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li.current-menu-item a:before {
     content: '';
     display: none;
   }
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu li a {
     display: block;
     color: #fff;
     width: 100%;
     padding: 15px 0 20px 0;
   }
   header.site-header .site-navigation-bar .navigation-bar.active {
     opacity: 1;
     -webkit-transform: translateY(0);
         -ms-transform: translateY(0);
             transform: translateY(0);
     -webkit-transition-duration: 300ms;
             transition-duration: 300ms;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li {
     -webkit-transform: scale(1) translateY(0);
         -ms-transform: scale(1) translateY(0);
             transform: scale(1) translateY(0);
     opacity: 1;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(1) {
     -webkit-transition-delay: 0.27s;
             transition-delay: 0.27s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(2) {
     -webkit-transition-delay: 0.34s;
             transition-delay: 0.34s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(3) {
     -webkit-transition-delay: 0.41s;
             transition-delay: 0.41s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(4) {
     -webkit-transition-delay: 0.48s;
             transition-delay: 0.48s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(5) {
     -webkit-transition-delay: 0.55s;
             transition-delay: 0.55s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(6) {
     -webkit-transition-delay: 0.62s;
             transition-delay: 0.62s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(7) {
     -webkit-transition-delay: 0.69s;
             transition-delay: 0.69s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(8) {
     -webkit-transition-delay: 0.76s;
             transition-delay: 0.76s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(9) {
     -webkit-transition-delay: 0.83s;
             transition-delay: 0.83s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(10) {
     -webkit-transition-delay: 0.9s;
             transition-delay: 0.9s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(11) {
     -webkit-transition-delay: 0.97s;
             transition-delay: 0.97s;
   }
   header.site-header .site-navigation-bar .navigation-bar.active .nav-menu ul.menu li:nth-child(12) {
     -webkit-transition-delay: 1.04s;
             transition-delay: 1.04s;
   }
   header.site-header .site-navigation-bar .toggle-menu {
     display: block;
     right: 0;
     float: right;
     font-size: 3rem;
     color: #fff;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger {
     cursor: pointer;
     width: 40px;
     height: 32px;
     margin: auto;
     position: relative;
     float: right;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger i {
     background-color: #fff;
     content: '';
     display: block;
     width: 100%;
     height: 2px;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger i:nth-child(1) {
     -webkit-animation: sera-outT 0.8s backwards;
             animation: sera-outT 0.8s backwards;
     -webkit-animation-direction: reverse;
             animation-direction: reverse;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger i:nth-child(2) {
     margin: 12px 0;
     -webkit-animation: sera-outM 0.8s backwards;
             animation: sera-outM 0.8s backwards;
     -webkit-animation-direction: reverse;
             animation-direction: reverse;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger i:nth-child(3) {
     -webkit-animation: sera-outBtm 0.8s backwards;
             animation: sera-outBtm 0.8s backwards;
     -webkit-animation-direction: reverse;
             animation-direction: reverse;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger.active i:nth-child(1) {
     -webkit-animation: sera-inT 0.8s forwards;
             animation: sera-inT 0.8s forwards;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger.active i:nth-child(2) {
     -webkit-animation: sera-inM 0.8s forwards;
             animation: sera-inM 0.8s forwards;
   }
   header.site-header .site-navigation-bar .toggle-menu .navTrigger.active i:nth-child(3) {
     -webkit-animation: sera-inBtm 0.8s forwards;
             animation: sera-inBtm 0.8s forwards;
   }
 }
 
 @media (max-width: 1000px) and (min-width: 550px) {
   header.site-header .header-banner .heading-title h1 {
     font-size: 3rem;
   }
   header.site-header .header-banner .heading-title h2 {
     font-size: 1.75rem;
   }
   header.site-header .site-navigation-bar .navigation-bar {
     left: 60%;
     width: 40%;
     -webkit-transform: translate(100%, 0);
         -ms-transform: translate(100%, 0);
             transform: translate(100%, 0);
     -webkit-transition: -webkit-transform 0.8s ease-in-out;
     transition: -webkit-transform 0.8s ease-in-out;
     transition: transform 0.8s ease-in-out;
     transition: transform 0.8s ease-in-out, -webkit-transform 0.8s ease-in-out;
   }
   header.site-header .site-navigation-bar .navigation-bar.active {
     -webkit-transform: translate(0);
         -ms-transform: translate(0);
             transform: translate(0);
   }
 }
 
 @media (max-width: 720px) and (min-width: 550px) {
   header.site-header .site-navigation-bar .navigation-bar .nav-menu ul.menu {
     padding: 0 20px;
   }
 }
 
 @media (max-width: 550px) {
   header.site-header .header-banner .heading-title h1 {
     font-size: 2.4rem;
   }
   header.site-header .header-banner .heading-title h2 {
     font-size: 1.3rem;
   }
   header.site-header .header-banner .buttons-header .button-cta {
     font-size: 1rem;
   }
 }
 
 /**
  *
  *  Sections Style
  *
  *  @package gleesik_layout
  *
  **/
 section.site-section {
   padding: 100px 0;
   background: #fff;
 }
 
 section.site-section header.section-header h1, section.site-section header.section-header h2, section.site-section header.section-header h3, section.site-section header.section-header h4, section.site-section header.section-header h5, section.site-section header.section-header h6 {
   font-weight: bold;
   text-transform: uppercase;
   color: #2c3e50;
 }
 
 section.site-section header.section-header hr.normal {
   margin: 40px auto;
 }
 
 section.site-section.section-focus {
   background-color: #f4f8f9;
 }
 
 section.site-section.section-focus.next-section-indicator:after {
   border-top-color: #f4f8f9;
 }
 
 section.site-section.next-section-indicator {
   position: relative;
 }
 
 section.site-section.next-section-indicator:after {
   content: '';
   position: absolute;
   width: 0;
   height: 0;
   bottom: -30px;
   left: 50%;
   z-index: 3;
   -webkit-transform: translate(-50%);
       -ms-transform: translate(-50%);
           transform: translate(-50%);
   border-left: 30px solid transparent;
   border-right: 30px solid transparent;
   border-top: 30px solid #fff;
 }
 
 /**
  *
  *  Sidebar Aside Style
  *
  *  @package gleesik_layout
  *
  **/
 aside.site-sidebar .widget {
   margin-bottom: 60px;
 }
 
 aside.site-sidebar .widget header.widget-header {
   padding: 24px 30px;
   color: #2c3e50;
   margin-bottom: 30px;
   background-color: #f4f8f9;
 }
 
 aside.site-sidebar .widget header.widget-header h4.widget-title {
   font-size: 1.25rem;
   text-transform: uppercase;
 }
 
 aside.site-sidebar .about-widget .widget-content .social-icons {
   margin-top: 10px;
 }
 
 aside.site-sidebar .about-widget .widget-content .social-icons .icons a .fi {
   display: inline-block;
   font-size: 1.48rem;
   margin-bottom: 10px;
 }
 
 aside.site-sidebar .about-widget .widget-content .info {
   display: block;
   position: relative;
   clear: both;
 }
 
 aside.site-sidebar .about-widget .widget-content .info .author-name {
   font-size: 1.25rem;
   color: #2c3e50;
   text-transform: uppercase;
   margin-bottom: 6px;
 }
 
 aside.site-sidebar .about-widget .widget-content .info .author-info {
   color: #bdc3c7;
 }
 
 aside.site-sidebar .about-widget .widget-content .info hr.medium {
   float: left;
   margin-top: 20px;
   margin-bottom: 20px;
 }
 
 aside.site-sidebar .about-widget .widget-content .author-text {
   font-size: 0.8rem;
 }
 
 aside.site-sidebar .categories-widget .widget-content ul.categories-box li a {
   display: block;
   padding: 20px 30px;
   color: #5e5e5e;
   border-bottom: 1px solid #f4f8f9;
 }
 
 aside.site-sidebar .categories-widget .widget-content ul.categories-box li a .cat-count {
   float: right;
   margin-top: -3px;
   padding: 5px 6px;
   -webkit-border-radius: 3px;
           border-radius: 3px;
   background: #f4f8f9;
   font-size: 0.6875rem;
   -webkit-transition: color, background 300ms ease-in-out;
   transition: color, background 300ms ease-in-out;
 }
 
 aside.site-sidebar .categories-widget .widget-content ul.categories-box li a:hover {
   color: #21c2f8;
 }
 
 aside.site-sidebar .categories-widget .widget-content ul.categories-box li a:hover .cat-count {
   color: #fff;
   background: #21c2f8;
 }
 
 aside.site-sidebar .categories-widget .widget-content ul.categories-box li:last-child a {
   border-bottom: 0;
 }
 
 aside.site-sidebar .newsletter-widget .widget-content form.newsletter-email-signup .field-group {
   margin-bottom: 20px;
 }
 
 aside.site-sidebar .newsletter-widget .widget-content form.newsletter-email-signup .field-group input {
   border-width: 2px;
   padding: 20px;
 }
 
 aside.site-sidebar .newsletter-widget .widget-content form.newsletter-email-signup .button-subscribe {
   padding: 20px;
   text-transform: uppercase;
   letter-spacing: 0.08rem;
 }
 
 aside.site-sidebar .newsletter-widget .widget-content form.newsletter-email-signup p {
   margin-top: 10px;
 }
 
 /**
  *
  *  Offer Style
  *
  *  @package gleesik_layout
  *
  **/
 section.offer-section {
   padding: 40px 0;
   color: #fff;
   background-color: #21c2f8;
 }
 
 section.offer-section h2 {
   text-transform: uppercase;
   font-size: 1.75rem;
   padding: 1.125rem 0;
 }
 
 @media (max-width: 992px) {
   section.offer-section h2 {
     text-align: center;
     line-height: 1.4;
     padding: 0;
     padding-bottom: 40px;
   }
 }
 
 section.offer-section .button-cta {
   margin-bottom: 0;
 }
 
 /**
  *
  *  About Section Style
  *
  *  @package gleesik_layout
  *
  **/
 /**
  *
  *  Features Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.features-section .features {
   margin-top: 80px;
 }
 
 @media (max-width: 992px) {
   section.features-section .feature {
     margin-bottom: 80px;
   }
   section.features-section .feature:last-child {
     margin-bottom: 0;
   }
 }
 
 section.features-section .feature .icon .circle {
   width: -webkit-calc(50% - 15px);
   width: calc(50% - 15px);
   padding-bottom: -webkit-calc(50% - 23px);
   padding-bottom: calc(50% - 23px);
   margin: 0 auto;
   margin-bottom: 40px;
   border: 4px solid #21c2f8;
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.features-section .feature .icon .circle .circle {
   width: 50%;
   padding-bottom: 50%;
   border: 0;
   background: #bdc3c7;
 }
 
 section.features-section .feature .icon .circle .circle i {
   font-size: 3rem;
   color: #2c3e50;
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.features-section .feature .icon .circle .circle i:before {
   padding-left: 3%;
 }
 
 section.features-section .feature .content h3 {
   color: #2c3e50;
   text-transform: uppercase;
   margin-bottom: 1.25rem;
 }
 
 section.features-section .feature .content h3, section.features-section .feature .content p {
   -webkit-transition: color 300ms ease-in-out;
   transition: color 300ms ease-in-out;
 }
 
 section.features-section .feature:hover .icon .circle {
   border-color: #bdc3c7;
 }
 
 section.features-section .feature:hover .icon .circle .circle {
   background: #21c2f8;
 }
 
 section.features-section .feature:hover .icon .circle .circle i {
   color: #fff;
 }
 
 section.features-section .feature:hover .content h3 {
   color: #21c2f8;
 }
 
 section.features-section .feature:hover .content p {
   color: #2c3e50;
 }
 
 /**
  *
  *  Portfolio Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.portfolio-section .filter-menu-wrapper {
   margin: 50px 0;
 }
 
 section.portfolio-section .filter-menu-wrapper .filter-menu {
   display: block;
   width: 100%;
   background-color: #f4f8f9;
   -webkit-border-radius: 3px;
           border-radius: 3px;
   letter-spacing: normal;
   text-align: center;
 }
 
 section.portfolio-section .filter-menu-wrapper .filter-menu ul {
   display: inline-block;
   position: relative;
   margin: 0 auto;
 }
 
 section.portfolio-section .filter-menu-wrapper .filter-menu ul li {
   display: inline-block;
   position: relative;
   padding: 15px;
   cursor: pointer;
   color: #2c3e50;
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.portfolio-section .filter-menu-wrapper .filter-menu ul li:hover {
   color: #21c2f8;
 }
 
 section.portfolio-section .filter-menu-wrapper .filter-menu ul li.current {
   color: #21c2f8;
 }
 
 section.portfolio-section .filter-menu-wrapper .filter-menu ul li.current:before {
   content: '';
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 2px;
   background: #21c2f8;
 }
 
 section.portfolio-section .portfolio-grid {
   overflow-x: hidden;
 }
 
 section.portfolio-section .portfolio-grid .item {
   display: block;
   position: relative;
   overflow: hidden;
   margin-bottom: 30px;
   -webkit-transform: scale(0.95);
       -ms-transform: scale(0.95);
           transform: scale(0.95);
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.portfolio-section .portfolio-grid .item .img-wrap {
   position: relative;
 }
 
 section.portfolio-section .portfolio-grid .item .img-wrap img {
   width: 100%;
   display: block;
 }
 
 section.portfolio-section .portfolio-grid .item.visible {
   opacity: 0;
   -webkit-transform: scale(1) translateX(12%);
       -ms-transform: scale(1) translateX(12%);
           transform: scale(1) translateX(12%);
 }
 
 section.portfolio-section .portfolio-grid .item.visible:hover .item-content {
   -webkit-transform: translateY(0);
       -ms-transform: translateY(0);
           transform: translateY(0);
 }
 
 section.portfolio-section .portfolio-grid .item.is-showing {
   opacity: 0.3;
 }
 
 section.portfolio-section .portfolio-grid .item.is-showing.visible {
   opacity: 1;
   -webkit-transform: scale(1) translateX(0);
       -ms-transform: scale(1) translateX(0);
           transform: scale(1) translateX(0);
 }
 
 section.portfolio-section .portfolio-grid .item .item-content {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -webkit-flex-direction: column;
       -ms-flex-direction: column;
           flex-direction: column;
   -webkit-box-align: center;
   -webkit-align-items: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
       -ms-flex-pack: center;
           justify-content: center;
   position: absolute;
   top: 0;
   left: 15px;
   right: 15px;
   bottom: 0;
   color: #fff;
   background: rgba(33, 194, 248, 0.8);
   -webkit-transform: translateY(100%);
       -ms-transform: translateY(100%);
           transform: translateY(100%);
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.portfolio-section .portfolio-grid .item .item-content h3, section.portfolio-section .portfolio-grid .item .item-content h4 {
   text-transform: uppercase;
 }
 
 section.portfolio-section .portfolio-grid .item .item-content h3 {
   font-size: 2rem;
   margin-bottom: 10px;
 }
 
 section.portfolio-section .portfolio-grid .item .item-content .links {
   margin-top: 20px;
 }
 
 section.portfolio-section .portfolio-grid .item .item-content .links ul li {
   display: inline-block;
   font-size: 1.9rem;
 }
 
 section.portfolio-section .portfolio-grid .item .item-content .links ul li a {
   display: block;
   padding: 5px;
   color: #fff;
   background: rgba(255, 255, 255, 0.2);
 }
 
 section.portfolio-section .portfolio-grid .item .item-content .links ul li a:hover {
   background: rgba(255, 255, 255, 0.4);
 }
 
 section.portfolio-section .portfolio-grid .overlay {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   background: rgba(0, 0, 0, 0.9);
   z-index: 99999999999999;
 }
 
 section.portfolio-section .portfolio-grid .overlay img {
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   -webkit-transform: translate(-50%, -50%);
       -ms-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
 }
 
 /**
  *
  *  Parallax Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.parallax-features-section {
   position: relative;
   padding-bottom: 50px;
   color: #f4f8f9;
 }
 
 section.parallax-features-section header.section-header h2, section.parallax-features-section header.section-header h3, section.parallax-features-section header.section-header h4 {
   color: #fff;
 }
 
 section.parallax-features-section header.section-header hr {
   background: #fff;
 }
 
 section.parallax-features-section .section-features {
   margin-top: 80px;
 }
 
 section.parallax-features-section .section-features .phone img {
   margin-bottom: -90%;
 }
 
 section.parallax-features-section .section-features .text-left {
   margin-top: 10%;
 }
 
 section.parallax-features-section .section-features .text-left .icon-wrap {
   margin-right: 30px;
   float: left;
 }
 
 section.parallax-features-section .section-features .text-right {
   margin-top: 10%;
 }
 
 section.parallax-features-section .section-features .text-right .icon-wrap {
   margin-left: 30px;
   float: right;
 }
 
 section.parallax-features-section .section-features .feature {
   display: block;
   width: 100%;
   margin-bottom: 30px;
 }
 
 section.parallax-features-section .section-features .feature .text-wrap {
   display: inline-block;
   width: -webkit-calc(100% - 100px);
   width: calc(100% - 100px);
 }
 
 section.parallax-features-section .section-features .feature .text-wrap h3 {
   color: #21c2f8;
   font-size: 1.25rem;
   margin-bottom: 10px;
 }
 
 section.parallax-features-section .section-features .feature .text-wrap p {
   color: #bdc3c7;
   font-size: 0.875rem;
 }
 
 section.parallax-features-section .section-features .feature .icon-wrap {
   display: block;
 }
 
 section.parallax-features-section .section-features .feature .icon-wrap .circle {
   width: 70px;
   height: 70px;
   font-size: 2rem;
   background-color: rgba(255, 255, 255, 0.2);
 }
 
 section.parallax-features-section .section-features .feature, section.parallax-features-section .section-features .feature p, section.parallax-features-section .section-features .feature .icon-wrap .circle {
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.parallax-features-section .section-features .feature:hover p {
   color: #fff;
 }
 
 section.parallax-features-section .section-features .feature:hover .icon-wrap .circle {
   background-color: #21c2f8;
 }
 
 @media (max-width: 991px) {
   section.parallax-features-section .section-features .phone {
     display: none;
   }
   section.parallax-features-section .section-features .text-left {
     margin-top: 10%;
   }
   section.parallax-features-section .section-features .text-left .icon-wrap {
     margin-right: 30px;
     float: left;
   }
   section.parallax-features-section .section-features .text-right {
     margin-top: 10%;
     text-align: left;
   }
   section.parallax-features-section .section-features .text-right .icon-wrap {
     margin-left: 0;
     margin-right: 30px;
     float: left;
   }
 }
 
 /**
  *
  *  Testimonials Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.testimonials-section {
   overflow: hidden;
 }
 
 section.testimonials-section .client-testimonial {
   display: block;
   position: relative;
   margin-top: 50px;
   width: 100%;
   height: auto;
 }
 
 section.testimonials-section .client-testimonial .testimonial-face {
   width: 30%;
   height: auto;
   margin: 0 auto;
 }
 
 @media (max-width: 767px) {
   section.testimonials-section .client-testimonial .testimonial-face {
     width: 60%;
   }
 }
 
 @media (max-width: 991px) and (min-width: 767px) {
   section.testimonials-section .client-testimonial .testimonial-face {
     width: 40%;
   }
 }
 
 section.testimonials-section .client-testimonial .testimonial-face .face {
   padding: 20px;
 }
 
 section.testimonials-section .client-testimonial .testimonial-face .face img {
   -webkit-border-radius: 100%;
           border-radius: 100%;
 }
 
 section.testimonials-section .client-testimonial .testimonial-face .info h3.name {
   color: #2c3e50;
   font-size: 1.5625rem;
   text-transform: uppercase;
   margin-bottom: 10px;
 }
 
 section.testimonials-section .client-testimonial .testimonial-face .info a.link {
   font-size: 0.875rem;
 }
 
 section.testimonials-section .client-testimonial .testimonial-quote {
   position: relative;
   width: -webkit-calc(100% - 30px);
   width: calc(100% - 30px);
   padding: 30px 12%;
   margin: 40px 15px 0 15px;
   -webkit-border-radius: 3px;
           border-radius: 3px;
   background: #e3e9ed;
 }
 
 section.testimonials-section .client-testimonial .testimonial-quote:before {
   content: '';
   position: absolute;
   width: 0;
   height: 0;
   top: -18px;
   left: 50%;
   z-index: 3;
   -webkit-transform: translate(-50%);
       -ms-transform: translate(-50%);
           transform: translate(-50%);
   border-left: 18px solid transparent;
   border-right: 18px solid transparent;
   border-bottom: 18px solid #e3e9ed;
 }
 
 section.testimonials-section .client-testimonial .testimonial-quote .quote {
   position: relative;
   color: #2c3e50;
   font-style: italic;
   font-size: 1rem;
   line-height: 1.4;
   letter-spacing: 0.08rem;
 }
 
 section.testimonials-section .client-testimonial .testimonial-quote .quote:before, section.testimonials-section .client-testimonial .testimonial-quote .quote:after {
   display: block;
   position: absolute;
   content: "\"";
   font-size: 3.125rem;
   font-weight: bold;
 }
 
 section.testimonials-section .client-testimonial .testimonial-quote .quote:before {
   left: -2rem;
   top: 0;
 }
 
 section.testimonials-section .client-testimonial .testimonial-quote .quote:after {
   right: -1rem;
   bottom: -1rem;
 }
 
 section.testimonials-section .client-testimonial .testimonial-quote .logo {
   display: block;
   position: relative;
   width: 30%;
   height: auto;
   margin: 20px auto 0 auto;
 }
 
 @media (max-width: 767px) {
   section.testimonials-section .client-testimonial .testimonial-quote .logo {
     width: 50%;
   }
 }
 
 @media (max-width: 991px) and (min-width: 767px) {
   section.testimonials-section .client-testimonial .testimonial-quote .logo {
     width: 40%;
   }
 }
 
 section.testimonials-section .owl-theme .owl-controls {
   margin-top: 10px;
   text-align: center;
 }
 
 section.testimonials-section .owl-theme .owl-controls.clickable .owl-buttons div:hover {
   opacity: 1;
   text-decoration: none;
 }
 
 section.testimonials-section .owl-theme .owl-controls .owl-buttons div {
   color: #fff;
   display: inline-block;
   zoom: 1;
   margin: 5px;
   padding: 5px 10px;
   font-size: 0.85rem;
   -webkit-border-radius: 30px;
           border-radius: 30px;
   background: #bdc3c7;
   opacity: 0.5;
 }
 
 section.testimonials-section .owl-theme .owl-controls .owl-page {
   display: inline-block;
   zoom: 1;
 }
 
 section.testimonials-section .owl-theme .owl-controls .owl-page span {
   display: block;
   width: 30px;
   height: 5px;
   margin: 20px 4px 10px 4px;
   -webkit-border-radius: 3px;
           border-radius: 3px;
   background: #bdc3c7;
 }
 
 section.testimonials-section .owl-theme .owl-controls .owl-page.active span, section.testimonials-section .owl-theme .owl-controls .owl-page:hover span {
   background: #21c2f8;
 }
 
 /**
  *
  *  Blog Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.blog-section.page-layout {
   padding-top: 30px;
 }
 
 section.blog-section.page-layout .blog-articles-container {
   margin-top: 0;
 }
 
 section.blog-section.page-layout article.blog-article .article-boxes {
   float: left;
   width: 100%;
   padding: 50px 0;
 }
 
 section.blog-section.page-layout article.blog-article .article-boxes .box {
   display: block;
   width: 100%;
   float: left;
   margin-bottom: 30px;
 }
 
 section.blog-section.page-layout article.blog-article .article-boxes .box:last-child {
   margin-bottom: 0;
 }
 
 section.blog-section.page-layout article.blog-article .article-boxes .box span.title {
   float: left;
   color: #2c3e50;
   font-weight: bold;
   width: 100px;
 }
 
 @media (max-width: 768px) {
   section.blog-section.page-layout article.blog-article .article-boxes .box span.title {
     width: 100%;
     font-size: 1.2rem;
     padding-top: 0;
     margin-bottom: 20px;
   }
   section.blog-section.page-layout article.blog-article .article-boxes .box.share-article ul a {
     font-size: 2.5rem;
   }
 }
 
 section.blog-section.page-layout article.blog-article .article-boxes .box .box-content {
   float: left;
 }
 
 section.blog-section.page-layout article.blog-article .article-boxes .tags-article span.title {
   padding-top: 8px;
 }
 
 section.blog-section.page-layout article.blog-article .article-boxes .share-article span.title {
   padding-top: 0.2rem;
 }
 
 section.blog-section .blog-articles-container {
   margin-top: 80px;
 }
 
 @media (max-width: 430px) {
   section.blog-section .blog-articles-container .col-md-4 {
     width: 100%;
     left: 0;
   }
 }
 
 section.blog-section .blog-articles-container article.blog-article {
   display: block;
   position: relative;
   width: 100%;
   float: left;
   margin-bottom: 30px;
 }
 
 section.blog-section .blog-articles-container article.blog-article.blog-single-page header.article-header .article-title {
   margin: 50px 0;
 }
 
 section.blog-section .blog-articles-container article.blog-article.blog-single-page header.article-header .article-title h3.title {
   color: #2c3e50;
   font-size: 2rem;
 }
 
 section.blog-section .blog-articles-container article.blog-article header.article-header {
   display: block;
   position: relative;
   width: 100%;
   float: left;
 }
 
 section.blog-section .blog-articles-container article.blog-article header.article-header .img-wrap {
   display: block;
   width: 100%;
   height: auto;
 }
 
 section.blog-section .blog-articles-container article.blog-article header.article-header .article-title {
   display: block;
   width: 100%;
   margin: 30px 0;
 }
 
 section.blog-section .blog-articles-container article.blog-article header.article-header .article-title h3.title {
   font-size: 1.6rem;
   line-height: 1.4;
   text-transform: uppercase;
   margin-bottom: 10px;
 }
 
 section.blog-section .blog-articles-container article.blog-article header.article-header .article-title h3.title a {
   color: #2c3e50;
 }
 
 section.blog-section .blog-articles-container article.blog-article header.article-header .article-title h3.title a:hover, section.blog-section .blog-articles-container article.blog-article header.article-header .article-title h3.title a:active {
   color: #21c2f8;
 }
 
 section.blog-section .blog-articles-container article.blog-article header.article-header .article-title h4.category {
   color: #21c2f8;
   text-transform: capitalize;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content {
   display: block;
   position: relative;
   float: left;
   width: 100%;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .article-date {
   width: 100px;
   float: left;
   padding-right: 30px;
   color: #fff;
   text-transform: uppercase;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .article-date .date {
   width: 100%;
   padding-bottom: 100%;
   margin-bottom: 5px;
   background: #21c2f8;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .article-date .date .day {
   font-size: 1.875rem;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .article-date .date .month {
   font-size: 1rem;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .article-date .time {
   width: 100%;
   padding: 10px 0;
   background: #21c2f8;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .article-text {
   width: -webkit-calc(100% - 100px);
   width: calc(100% - 100px);
   font-size: 0.9rem;
   float: left;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .article-text p {
   line-height: 1.4;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .show-more {
   display: block;
   width: 100%;
   margin-top: 20px;
   text-align: center;
   float: left;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .show-more .button {
   float: left;
   margin: 0 0 0 100px;
 }
 
 section.blog-section .blog-articles-container article.blog-article .article-content .show-more .buttonRight {
   float: right;
   margin: 0 0 0 100px;
 }
 
 section.blog-section .view-posts hr.normal {
   margin: 20px auto;
 }
 
 section.blog-section .view-posts .button-cta {
   margin-bottom: 0;
   float: inherit !important;
 }
 
 /**
  *
  *  Team
   Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.team-section .team-members-container {
   margin-top: 80px;
 }
 
 @media (max-width: 600px) and (min-width: 430px) {
   section.team-section .team-members-container .col-md-3 {
     width: 70%;
     margin-left: 15%;
     margin-right: 15%;
   }
 }
 
 @media (max-width: 430px) {
   section.team-section .team-members-container .col-md-3 {
     width: 100%;
   }
 }
 
 section.team-section .team-members-container .team-member {
   background: #fff;
   margin-bottom: 30px;
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.team-section .team-members-container .team-member .img-wrapper {
   display: block;
   position: relative;
   overflow: hidden;
 }
 
 section.team-section .team-members-container .team-member .img-wrapper:before, section.team-section .team-members-container .team-member .img-wrapper:after {
   content: '';
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   width: 100%;
   height: 100%;
   background: rgba(33, 194, 248, 0.8);
   z-index: 1;
   opacity: 0;
   -webkit-transform: translate(-50%, -50%);
       -ms-transform: translate(-50%, -50%);
           transform: translate(-50%, -50%);
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.team-section .team-members-container .team-member .img-wrapper:after {
   content: "\e623";
   font-family: '7-stroke';
   font-size: 4rem;
   width: auto;
   height: auto;
   color: #fff;
   background: transparent;
 }
 
 section.team-section .team-members-container .team-member .team-member-content {
   padding: 30px 30px 20px 30px;
 }
 
 section.team-section .team-members-container .team-member .team-member-content h3.name {
   font-size: 1.25rem;
   line-height: 1.4;
   color: #2c3e50;
   text-transform: uppercase;
   margin-bottom: 5px;
 }
 
 section.team-section .team-members-container .team-member .team-member-content h4.tagline {
   font-size: 0.875rem;
 }
 
 section.team-section .team-members-container .team-member .team-member-content .social-icons {
   display: block;
   width: 100%;
   text-align: center;
   font-size: 1.4rem;
   margin-top: 30px;
 }
 
 section.team-section .team-members-container .team-member .team-member-content .social-icons .icons {
   display: inline-block;
 }
 
 section.team-section .team-members-container .team-member .team-member-content .social-icons .icons li {
   margin-bottom: 10px;
 }
 
 section.team-section .team-members-container .team-member .team-member-content .social-icons .icons li a {
   color: #5e5e5e;
 }
 
 section.team-section .team-members-container .team-member .team-member-content .social-icons .icons li a:hover, section.team-section .team-members-container .team-member .team-member-content .social-icons .icons li a:active {
   color: #21c2f8;
 }
 
 section.team-section .team-members-container .team-member:hover {
   -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.52);
           box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.52);
 }
 
 section.team-section .team-members-container .team-member:hover .img-wrapper:before, section.team-section .team-members-container .team-member:hover .img-wrapper:after {
   opacity: 1;
 }
 
 /**
  *
  *  Prices Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.prices-section .pricing-tables-container {
   margin-top: 80px;
 }
 
 section.prices-section .pricing-tables-container .pricing-tables-wrapper {
   display: -webkit-box;
   display: -webkit-flex;
   display: -ms-flexbox;
   display: flex;
   -webkit-box-orient: horizontal;
   -webkit-box-direction: normal;
   -webkit-flex-direction: row;
       -ms-flex-direction: row;
           flex-direction: row;
   -webkit-box-align: center;
   -webkit-align-items: center;
       -ms-flex-align: center;
           align-items: center;
   -webkit-box-pack: center;
   -webkit-justify-content: center;
       -ms-flex-pack: center;
           justify-content: center;
   position: relative;
   width: 100%;
 }
 
 @media (max-width: 991px) and (min-width: 400px) {
   section.prices-section .pricing-tables-container .pricing-tables-wrapper {
     display: block;
   }
   section.prices-section .pricing-tables-container .pricing-tables-wrapper .pricing-table {
     margin-bottom: 30px;
   }
 }
 
 @media (max-width: 400px) {
   section.prices-section .pricing-tables-container .pricing-tables-wrapper {
     display: block;
   }
   section.prices-section .pricing-tables-container .pricing-tables-wrapper .col-md-3 {
     width: 100% !important;
     left: 0;
     margin-bottom: 30px;
   }
 }
 
 section.prices-section .pricing-tables-container .pricing-table {
   position: relative;
   border: 1px solid #bdc3c7;
   text-align: center;
   background: #fff;
   -webkit-transition: all 300ms ease-in-out;
   transition: all 300ms ease-in-out;
 }
 
 section.prices-section .pricing-tables-container .pricing-table.pricing-table-focus .plan-head {
   position: relative;
   padding-top: 90px;
 }
 
 section.prices-section .pricing-tables-container .pricing-table.pricing-table-focus .plan-head:before {
   content: '';
   display: block;
   position: absolute;
   width: -webkit-calc(100% + 2px);
   width: calc(100% + 2px);
   height: 4px;
   top: -1px;
   left: -1px;
   background: #21c2f8;
 }
 
 section.prices-section .pricing-tables-container .pricing-table.pricing-table-focus .plan-action {
   padding-bottom: 40px;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-head {
   padding: 50px 0;
   font-weight: bold;
   border-bottom: 1px solid #bdc3c7;
   overflow: hidden;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-head .offer {
   display: block;
   position: absolute;
   top: 0;
   left: 0;
   width: 120%;
   height: auto;
   color: #fff;
   font-size: 0.75rem;
   text-align: center;
   text-transform: uppercase;
   padding: 10px 0;
   background: #21c2f8;
   -webkit-transform: rotate(-45deg) translate(-32%, -160%);
       -ms-transform: rotate(-45deg) translate(-32%, -160%);
           transform: rotate(-45deg) translate(-32%, -160%);
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-head .plan-title {
   text-transform: uppercase;
   color: #2c3e50;
   font-size: 1.25rem;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-head hr.normal {
   width: 60px;
   height: 2px;
   margin-top: 20px;
   margin-bottom: 20px;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-head hr.normal:after {
   height: 2px;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-head .plan-subtitle {
   font-size: 0.875rem;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-content {
   padding: 40px 22px;
   border-bottom: 1px solid #bdc3c7;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-content h4.feature-title {
   font-weight: bold;
   color: #2c3e50;
   font-size: 1rem;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-content h4.feature-title .check {
   color: #2ecc71;
   font-size: 1.1em;
   margin-right: 4px;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-content hr.separator {
   margin: 20px auto;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-content p.feature-content {
   font-size: 0.75rem;
   margin: 20px 0;
 }
 
 section.prices-section .pricing-tables-container .pricing-table .plan-action {
   padding: 30px 10px;
 }
 
 section.prices-section .pricing-tables-container .pricing-table:hover {
   -webkit-box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.52);
           box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.52);
 }
 
 /**
  *
  *  Contact Section Style
  *
  *  @package gleesik_layout
  *
  **/
 section.contact-section {
   color: #f4f8f9;
 }
 
 section.contact-section header.section-header h2, section.contact-section header.section-header h3, section.contact-section header.section-header h4 {
   color: #fff;
 }
 
 section.contact-section header.section-header hr {
   background: #fff;
 }
 
 section.contact-section .form-container {
   margin-top: 80px;
 }
 
 section.contact-section .form-container form.contact-form .col-md-6, section.contact-section .form-container form.contact-form .col-md-12 {
   margin-bottom: 30px;
 }
 
 section.contact-section .form-container form.contact-form input, section.contact-section .form-container form.contact-form textarea {
   color: #fff;
 }
 
 section.contact-section .form-container form.contact-form input:hover, section.contact-section .form-container form.contact-form input:active, section.contact-section .form-container form.contact-form textarea:hover, section.contact-section .form-container form.contact-form textarea:active {
   color: #fff;
   border-color: #21c2f8;
   background: rgba(33, 194, 248, 0.25);
 }
 
 section.contact-section .form-container form.contact-form input:hover::-webkit-input-placeholder, section.contact-section .form-container form.contact-form input:active::-webkit-input-placeholder, section.contact-section .form-container form.contact-form textarea:hover::-webkit-input-placeholder, section.contact-section .form-container form.contact-form textarea:active::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
   color: #fff;
 }
 
 section.contact-section .form-container form.contact-form input:hover::-moz-placeholder, section.contact-section .form-container form.contact-form input:active::-moz-placeholder, section.contact-section .form-container form.contact-form textarea:hover::-moz-placeholder, section.contact-section .form-container form.contact-form textarea:active::-moz-placeholder {
   /* Firefox 19+ */
   color: #fff;
 }
 
 section.contact-section .form-container form.contact-form input:hover:-ms-input-placeholder, section.contact-section .form-container form.contact-form input:active:-ms-input-placeholder, section.contact-section .form-container form.contact-form textarea:hover:-ms-input-placeholder, section.contact-section .form-container form.contact-form textarea:active:-ms-input-placeholder {
   /* IE 10+ */
   color: #fff;
 }
 
 section.contact-section .form-container form.contact-form input:hover:-moz-placeholder, section.contact-section .form-container form.contact-form input:active:-moz-placeholder, section.contact-section .form-container form.contact-form textarea:hover:-moz-placeholder, section.contact-section .form-container form.contact-form textarea:active:-moz-placeholder {
   /* Firefox 18- */
   color: #fff;
 }
 
 section.contact-section .form-container form.contact-form .form-action {
   text-align: center;
   padding-top: 30px;
 }
 
 section.contact-section .form-container form.contact-form .form-action .send-button {
   text-transform: uppercase;
   width: 40%;
 }
 
 @media (max-width: 500px) {
   section.contact-section .form-container form.contact-form .form-action .send-button {
     display: block;
     width: 100%;
   }
 }
 
 /**
  *
  *  Footer Style
  *
  *  @package gleesik_layout
  *
  **/
 footer.site-footer {
   position: relative;
   color: #848484;
   background-color: #202020;
   width: 100%;
 }
 
 footer.site-footer .footer-info {
   padding: 80px 0;
 }
 
 footer.site-footer .footer-info .footer-logo {
   position: relative;
 }
 
 footer.site-footer .footer-info .footer-logo img {
   margin: 0 auto;
 }
 
 footer.site-footer .footer-info .footer-info-text {
   font-size: 0.875rem;
   padding: 35px 0;
 }
 
 footer.site-footer .footer-info .social-icons a {
   color: #5e5e5e;
 }
 
 footer.site-footer .footer-info .social-icons a:hover, footer.site-footer .footer-info .social-icons a:active {
   color: #21c2f8;
 }
 
 footer.site-footer .footer-copyright {
   font-size: 0.8125rem;
   border-top: 1px solid #464646;
   padding: 30px 0;
 }
 
 footer.site-footer .footer-copyright .footer-text {
   width: 100%;
 }
 