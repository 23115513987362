.About{
    text-align: center;
}

.White-logo{
    height: 40vmin;
    pointer-events: none;
}

.HTML-header-h1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(30px + 2vmin);
    color: white;
    margin-bottom: 50px;
}

.flexible{
    display: flex;
    align-items: center;
    justify-content: center;
}

img{
    width: 800px;
    max-width: 100%;
}

.HTML-header-image{
    flex-basis: 40%;
    padding: 5px;
}

.HTML-header-text{
    font-size: 30px;
    padding-left: 20px;
}

.List-body{
    padding: 1px;
}

.List-body ul{
    padding: 3px;
}

.indented-para{
    text-indent: 2em;
    text-align:start;
}

.questionorRequest{
    height: 300px;
    font-size: var(--field-fontsize);
}