.Form-container{
    margin: 5vmin;
    align-content: center;
}

.Sq-form{
    padding: 20;
    align-content: center;
    text-align: center;
}

.Booking-link{
    font-size: 65pt;
    align-items: center;
    color: white;
}
.Booking-link:hover{
    color: rgba(213, 0, 39, 0.964)
}

.blockholder{
    display: block;
    position: relative;
    background-color: white;
    height: 80vmin;
    /* width: 120vmin; */
}

.blockholder form{
    position: relative;
    left: 18%;
}