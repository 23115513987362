.About{
    text-align: center;
}

.White-logo{
    height: 40vmin;
    pointer-events: none;
}

.HTML-header{
    background-color: #282c34;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.HTML-body{
    background-color: #282c34;
    padding:20px;
    font-size: large;
}

.Services-body h1{
    text-align: center;
    color: white;
}
.features h2{
    background-color: #282c34;
    margin-top: 20px;
    font-size: 30pt;
    color: white;
}
.features-text{
    background-color: #282c34;
    margin: 5px;
    font-size: 20pt;
    color: white;
}
