.App {
  text-align: center;
}

.Welcome-Image {
  height: 40vmin;
  pointer-events: none;
}

.cover {
  display: block;
  position: relative;
}

.cover img{
  width: 200px;
  position: inherit;
}

.cover div{
  position: relative;
  height: auto;
  top: 0;
  left: 0;
  padding-top: 20px;
}

.cover div .cover-callout{
  height: auto;
  padding-bottom: 10px;
  top: 85%;
  bottom: 10px;
  /* transform: translateY(500%); */
  /* transform: translateX(-20%); */
}

.cover h2{
  font-size: 60px;
  padding-bottom: 10px;
}

.cover div .cover-callout div{
  font-size: 60px;
  /* padding-bottom: 10px; */
}

.cover hr{
  vertical-align: center;
  transform: translateY(30px);
}

.cover div .cover-callout hr{
  vertical-align: center;
  transform: translateY(30px);
}

.image-text{
  height: inherit;
  width: auto;
  display: inline-block;
  font-size: 60pt;
  background-color: #f0f2f63f;
}

.lower-image-text{
  height: inherit;
  width: auto;
  display: inline-block;
  background-color: #f0f2f63f;
}

.call-out-cards{
  display:inline-flex;
  /* flex-direction:; */
}

.paragraph-black-contrast{
  color: black;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #f0f2f63f;
}

.child-center-v {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.child-center-h {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}

.NavBar{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.NavItem{
  padding: 30px;
}